import * as React from "react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { Box, Backdrop, LinearProgress, Button } from "@mui/material"
import { gdsPlusSetup, wasmModule } from "@/gluelayer"
import { UDTabs } from "@/UDTreat/custom/UDTabs"
import { RightPage } from "@/UDTreat/right/RightPage"
import { setOpenProgressDlg, setProgressDlgMsg } from "@/UDTreat/udSetupSlice"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"

export const WePrinting = (props) => {
  const dispatch = useAppDispatch()
  const { orgId, patientId, caseId } = useParams()
  const preRoot = "test/pre/"

  const RunSaveArchStlStep = (step: number) => {
    if (step < 0) {
      console.log("Export saved zip file")
      const zipFileName = "case_" + caseId + "_arch.zip"
      dispatch(setProgressDlgMsg("Download FAB stls as zip file..."))
      setTimeout(() => {
        // wait 10ms to run current step
        gdsPlusSetup.DownloadFinishedArchStlZip(zipFileName) // download stls as zip
        dispatch(setOpenProgressDlg(false))
        dispatch(setProgressDlgMsg("End"))
      }, 10)
      return
    }
    console.log("Create arch stls, step=", step)
    dispatch(setProgressDlgMsg("Create Arch STLs ... step " + step.toString()))
    setTimeout(() => {
      // wait 10ms to run current step
      const nextStep = wasmModule.statusController.SaveArchStlStep(
        preRoot,
        step,
      )
      RunSaveArchStlStep(nextStep)
    }, 10)
  }

  const onClickSaveArchStl = () => {
    if (!wasmModule.isInit) return
    dispatch(setOpenProgressDlg(true))
    RunSaveArchStlStep(0)
  }

  return (
    <>
      <RightPage
        title="weDesign Printing"
        onClose={props.onClose}
        content={
          <UDTabs labels={["Export Fab STLs", "Test"]}>
            <Box>
              <Button variant="contained" onClick={onClickSaveArchStl}>
                Download arch stl
              </Button>
            </Box>
            <Box> Test, </Box>
          </UDTabs>
        }
        actions={<Box />}
      />
    </>
  )
}
