import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { Rnd } from "react-rnd"

import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"

import CloseIcon from "@mui/icons-material/CloseOutlined"
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { Radio, RadioGroup, Switch } from "@mui/material"

import type { IToothPercentInfo } from "@/gluelayer"
import { stagingManager, viewControlInTreatment } from "@/gluelayer"
import { wasmModule } from "@/gluelayer"
import { GlobalEvents, globalEvents } from "@/gluelayer"
import { stagingPercent } from "@/gluelayer"
import { stageControl } from "@/gluelayer"

import { FlowButton2 } from "../custom/FlowCustom"

import {
  setCurArch,
  setCurrentStep,
  setShowKFEditor,
  setUpdateStaging,
} from "../udTreatSlice"

import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import { use } from "i18next"

const PercentSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-mark": {
    height: 2,
    width: 15,
    backgroundColor: "#444",
  },
}))

let sscount = 0

export const KFEditor = (props) => {
  const theme = useTheme()
  const { curArch, currentStep, updateStaging, showKFEditor } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [isForwardOnly, setIsForwardOnly] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [isKeyFrame, setIsKeyFrame] = useState(false)
  const [isInitFinalKF, setIsInitFinalKF] = useState(false)
  const [smoothPercent, setSmoothPercent] = useState(false)
  const [dragxy, setDragxy] = useState({ x: 100, y: 200 })
  const [curKFIdx, setCurKFIdx] = useState(-1)
  const preKFIdx = useRef(-1)

  const upKFs = useRef([])
  const lowKFs = useRef([])
  const KFSteps = useRef([])

  const stagePercentData = useRef<IToothPercentInfo[]>()

  //const [stagePercentData, setStagePercentData] =
  // useState<IToothPercentInfo[]>()

  const setToPrevKFIdx = (idx) => {
    let newStage = KFSteps.current.at(idx)
    stageControl.setStageStep(newStage, newStage)
  }

  const checkKFSteps = (curStep) => {
    let n = KFSteps.current.length
    setIsInitFinalKF(curStep == 0 || curStep >= KFSteps.current[n - 1])
    const idx = KFSteps.current.findIndex((elem) => {
      return curStep === elem
    })
    setCurKFIdx(idx)
    setIsKeyFrame(idx >= 0)
  }

  const updateAll = () => {
    console.log("ssCount ", sscount)
    sscount++
    if (!wasmModule.isInit) return
    if (!showKFEditor) {
      stagingPercent.close()
    } else {
      if (curArch === "all") {
        dispatch(setShowKFEditor(false))
      } else {
        viewControlInTreatment.showArchMode(curArch === "up" ? "up" : "low")
        stagingPercent.update()
        KFSteps.current = stagingPercent.getKeyFrameSteps(curArch === "up")
        checkKFSteps(currentStep)
        const percentData = stagingPercent.getTeethPercentInfo(
          curArch === "up" ? true : false,
          currentStep,
        )
        let percentArray = stagePercentData.current
        const idx0 = curArch === "up" ? 1 : 17
        Object.entries(percentData).forEach(([key, data]) => {
          const idx = curArch === "up" ? Number(key) - 1 : 33 - Number(key)
          percentArray[idx] = data
        })
        console.log("ktw, percentArray", percentArray)
        stagePercentData.current = percentArray
        setUpdateInfo(!updateInfo)
      }
    }
  }

  const initPercentInfo: IToothPercentInfo = {
    enable: false,
    angle: 0,
    curStepIndex: 0,
    dist: 0,
    newStepNum: 0,
    percent: 0,
    prevKFPercent: 0,
    tID: 0,
    moveVec: [],
  }

  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    stagePercentData.current = new Array(16).fill(initPercentInfo)
  }, [])

  const onToothChangePercent = (tid, newPercent) => {
    const idx = (tid - 1) % 16
    const tdata = stagePercentData.current[idx]
    if (!tdata) return
    if (smoothPercent) {
      // total 3 tooth
      const weights = [0.2, 0.6, 1.0, 0.6, 0.2]
      const arch = tid <= 16 ? 0 : 1
      for (let i = 0; i < 5; i++) {
        const tid1 = tid + i - 2
        const idx1 = (tid1 - 1) % 16
        const arch1 = tid1 <= 16 ? 0 : 1
        if (arch1 != arch) continue
        const tdata1 = stagePercentData.current[idx1]
        if (!tdata1 || tdata1.curStepIndex < 0 || !tdata1.enable) continue
        const w = weights[i]
        let percent = w * newPercent + (1 - w) * tdata1.percent
        percent = Math.max(0.0, Math.min(1.0, percent))
        stagingPercent.setToothMove(tid1, percent)
        if (isForwardOnly) {
          // stagePercentData.UpdateNote
        }
      }
    } else {
      // only one tooth
      stagingPercent.setToothMove(tid, newPercent)
    }
    // ApplyChange(curArch)
  }

  const ApplyChange = (curArch) => {
    const isUp = curArch === "up"
    const curArchStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingPercent.addOrUpdateKeyFrame(
      isUp ? 0 : 1,
      curArchStage,
      isForwardOnly,
    )
    preKFIdx.current = curKFIdx
    dispatch(setUpdateStaging())
    setTimeout(() => {
      // Move cur stage to pre KF
      if (preKFIdx.current >= 0) {
        setToPrevKFIdx(preKFIdx.current)
      }
    }, 100)
  }

  const AddKeyFrame = (curArch) => {
    const isUp = curArch === "up"
    const curArchStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingManager.addNormalNode(isUp, curArchStage, false)
    dispatch(setUpdateStaging())
  }

  const DelKeyFrame = (curArch) => {
    const isUp = curArch === "up"
    const curArchStage = isUp
      ? stagingManager.stageupIndex
      : stagingManager.stagelowIndex
    stagingManager.deleteKeyNode(isUp, curArchStage)
    dispatch(setUpdateStaging())
  }

  const setShowManuStaging = (show: boolean) => {
    dispatch(setShowKFEditor(show))
  }

  const Top = () => {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        columns={12}
        alignItems="center"
      >
        <Grid item xs={4}>
          <FormControl size="small">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={curArch}
              value={curArch}
              onChange={(_, value) => {
                dispatch(setCurArch(value))
              }}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="up"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Upper</Typography>}
              />
              <FormControlLabel
                value="low"
                control={<Radio size="small" />}
                label={<Typography variant="body2">Lower</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FlowButton2
            disabled={isInitFinalKF || !isKeyFrame}
            onClick={() => {
              ApplyChange(curArch)
            }}
          >
            Apply
          </FlowButton2>
        </Grid>
        <Grid item xs={2}>
          <FlowButton2
            disabled={isKeyFrame}
            onClick={() => {
              AddKeyFrame(curArch)
            }}
          >
            Add KF
          </FlowButton2>
        </Grid>
        <Grid item xs={2}>
          <FlowButton2
            disabled={isInitFinalKF || !isKeyFrame}
            onClick={() => {
              DelKeyFrame(curArch)
            }}
          >
            Del KF
          </FlowButton2>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={<Switch checked={isForwardOnly} />}
            onChange={(_, value) => {
              setIsForwardOnly(value)
            }}
            label="Forward Only"
          />
        </Grid>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <Switch
                checked={smoothPercent}
                onChange={(e) => {
                  setSmoothPercent(e.target.checked)
                }}
              />
            }
            label="Smooth Percent"
          />
        </Grid>
      </Grid>
    )
  }
  const Left = () => {
    return (
      <Grid item xs={1.5}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2">Tooth</Typography>
          <Stack
            sx={{ height: 200 }}
            direction="column"
            justifyContent="space-between"
          >
            <Typography variant="body2" align="right">
              100%
            </Typography>
            <Typography variant="body2" align="right">
              50%
            </Typography>
            <Typography variant="body2" align="right">
              0%
            </Typography>
          </Stack>
          <Typography variant="body2">Dist</Typography>
          <Typography variant="body2">Angle</Typography>
        </Stack>
      </Grid>
    )
  }
  const OneTooth = ({ idx }) => {
    const [toothInfo, setToothInfo] =
      useState<IToothPercentInfo>(initPercentInfo)
    const [value, setValue] = useState<number>(0)
    const [prevValue, setPrevValue] = useState<number>(0)
    useEffect(() => {
      const info = stagePercentData.current[idx]
      setToothInfo(info)
      setValue(percentToValue(info.percent))
      setPrevValue(percentToValue(info.prevKFPercent))
    }, [updateInfo])
    const percentToValue = (percent: number) => {
      return Number.parseInt((percent * 100).toFixed(0))
    }
    return (
      stagePercentData.current && (
        <Grid item xs={1}>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2" align="center">
              {toothInfo.tID}
            </Typography>
            <PercentSlider
              size="small"
              sx={{ height: 200 }}
              orientation="vertical"
              value={value}
              min={0}
              max={100}
              valueLabelDisplay="auto"
              disabled={
                !toothInfo.enable ||
                toothInfo.curStepIndex < 0 ||
                !isKeyFrame ||
                isInitFinalKF
              }
              onChange={(_, val) => {
                const newVal = Math.max(val as number, prevValue)
                setValue(newVal)
                onToothChangePercent(toothInfo.tID, (newVal as number) / 100)
              }}
              onChangeCommitted={(_, val) => {
                setTimeout(() => {
                  ApplyChange(curArch)
                }, 100)
              }}
              marks={[{ value: prevValue }]}
            />
            <Typography variant="body2" align="center">
              {toothInfo.dist === 0 ? 0 : toothInfo.dist.toFixed(1)}
            </Typography>
            <Typography variant="body2" align="center">
              {toothInfo.angle === 0 ? 0 : toothInfo.angle.toFixed(1)}
            </Typography>
          </Stack>
        </Grid>
      )
    )
  }
  const idx = Array(16)
    .fill(0)
    .map((_, i) => i)
  const [updateInfo, setUpdateInfo] = useState(false)

  const PercentAllTeeth = () => {
    return (
      <Grid container spacing={0.1} alignItems="center" columns={17.5}>
        <Left />
        {stagePercentData.current &&
          idx.map((id) => {
            const tID = id + (curArch === "up" ? 1 : 17)
            return <OneTooth key={tID} idx={id} />
          })}
      </Grid>
    )
  }
  useEffect(() => {
    updateAll()
  }, [showKFEditor, curArch, updateStaging, currentStep])

  return showKFEditor ? (
    <Rnd
      position={{ x: dragxy.x, y: dragxy.y }}
      onDragStop={(e, d) => {
        setDragxy({ x: d.x, y: d.y })
      }}
      // onDrag={dragEventHandler}
      default={{
        x: 15,
        y: 50,
        width: 600,
        height: 300,
      }}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      bounds="window"
      // enableUserSelectHack={false}
      dragHandleClassName="my-drag-handle"
    >
      <Card sx={{}}>
        <CardHeader
          className="my-drag-handle"
          sx={{ height: "48px", backgroundColor: theme.transBkColor.light }}
          action={
            <IconButton
              aria-label="settings"
              sx={{ width: 30, height: 30 }}
              onClick={() => {
                setShowManuStaging(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          }
          titleTypographyProps={{ variant: "body1" }}
          title="Key Frame Editor"
        />
        <CardContent>
          <Top />
          <PercentAllTeeth />
        </CardContent>
      </Card>
    </Rnd>
  ) : (
    <></>
  )
}
