import { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

import {
  FormControlLabel,
  Stack,
  Switch,
  Divider,
  Button,
  Typography,
  Backdrop,
  Box,
  LinearProgress,
} from "@mui/material"
import { RightPage } from "@/UDTreat/right/RightPage"
import { WeCaseStatus } from "./WeCaseStatus"
import {
  wasmModule,
  caseManagement,
  resourcesSynchronization,
} from "@/gluelayer"
import { WeUploadCaseZip } from "./WeUploadCaseZip"

import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { setSwitchGum } from "@/UDTreat/udTreatSlice"
import { gdsPlusSetup } from "@/gluelayer"

export const WeTest = (props) => {
  const dispatch = useAppDispatch()

  const { orgId, patientId, caseId } = useParams()
  const [showStl, setShowStl] = useState(false)
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { switchGum } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const handleShowStl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowStl(event.target.checked)
  }
  const openCaseNewTab = () => {
    console.log("open a new tab")
    // navigate(`/clinical/patient/${patientId}/case/${caseId1}/treat`)
    const newRouteWithOrg = orgId
      ? `org/${orgId}/clinical/patient/${patientId}/case/${caseId}/treat`
      : `clinical/patient/${patientId}/case/${caseId}/treat`
    window.open(`${window.location.origin}/${newRouteWithOrg}`)
  }

  const downloadCaseZip = async () => {
    const zipName =
      "ulabcase_" +
      patientId.toString() +
      "_" +
      caseId.toString() +
      "_" +
      patientData.firstName +
      "_" +
      patientData.lastName +
      ".zip"
    if (wasmModule.isInit) {
      wasmModule.stagingcontoler.SaveMouthModel()
    }
    const files = await resourcesSynchronization.downloadCaseZip(zipName)
    console.log("Save", files)
  }

  const openCaseZipFile = useRef(null)
  const openCaseZip = (e) => {
    const { files } = e.target
    if (files && files.length) {
      const zipFileName = files[0]
      caseManagement.openCaseFromZip(
        document.getElementById("canvas") as HTMLCanvasElement,
        zipFileName,
      )
      console.log("OpenCaseZip: file=", zipFileName)
    }
  }

  useEffect(() => {
    if (wasmModule.isInit) {
      if (showStl) wasmModule.statusController.UpdateArchScan()
      wasmModule.statusController.ShowArchScan(showStl)
    }
  }, [showStl])

  useEffect(() => {
    if (!wasmModule.isInit) return
    wasmModule.statusController.ShowGum(switchGum)
  }, [switchGum])

  const onSwitchGum = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!wasmModule.isInit) return
    dispatch(setSwitchGum(event.target.checked))
  }

  const onClickSaveCase = (e) => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        const params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        dispatch(uploadCaseZips(params))
      }
    })
  }
  const onClickSaveScanStl = () => {
    const zipFileName = "case_" + caseId + "_scan.zip"
    gdsPlusSetup.DownloadScanStlZip(zipFileName)
  }
  const onClickSaveTeethStl = () => {
    const zipFileName = "case_" + caseId + "_teeth.zip"
    gdsPlusSetup.DownloadTeethStlZip(zipFileName)
  }

  const preRoot = "test/pre/"
  const [showProgressDlg, setShowProgressDlg] = useState(false)
  const [progressMsg, setProgressMsg] = useState("")
  const RunSaveArchStlStep = (step: number) => {
    if (step < 0) {
      console.log("Export saved zip file")
      const zipFileName = "case_" + caseId + "_arch.zip"
      setProgressMsg("Download FAB stls as zip file...")
      setTimeout(() => {
        // wait 10ms to run current step
        gdsPlusSetup.DownloadFinishedArchStlZip(zipFileName) // download stls as zip
        setShowProgressDlg(false)
        setProgressMsg("")
      }, 10)
      return
    }
    console.log("Create arch stls, step=", step)
    setProgressMsg("Create Arch STLs ... step " + step.toString())
    setTimeout(() => {
      // wait 10ms to run current step
      const nextStep = wasmModule.statusController.SaveArchStlStep(
        preRoot,
        step,
      )
      RunSaveArchStlStep(nextStep)
    }, 10)
  }

  useEffect(() => {
    console.log("WeTest  rerendered")
  }, [])

  const onClickSaveArchStl = () => {
    if (!wasmModule.isInit) return
    setShowProgressDlg(true)
    RunSaveArchStlStep(0)
  }

  const onClickUploadTeethTemplate = (e) => {
    const { files } = e.target
    if (files && files.length) {
      gdsPlusSetup.UploadTeethTemplate(files[0])
    }
  }
  const onClickUDToothShape = () => {
    if (!wasmModule.isInit) return
    wasmModule.stagingcontoler.buildToothShape("UDToothShape")
  }
  const onClickNextToothShape = () => {
    if (!wasmModule.isInit) return
    wasmModule.stagingcontoler.buildToothShape("NextToothShape")
  }
  const onClickTemplateToothShape = () => {
    if (!wasmModule.isInit) return
    wasmModule.stagingcontoler.buildToothShape("NextTemplate")
  }
  const onClickShowOrgTemplate = () => {
    if (!wasmModule.isInit) return
    wasmModule.stagingcontoler.buildToothShape("ShowOrgTemplate")
  }
  return (
    <RightPage
      title="WeDesign Staging"
      onClose={props.onClose}
      content={
        <Stack spacing={1}>
          <WeCaseStatus />
          <Divider />
          <FormControlLabel
            control={
              <Switch
                checked={showStl}
                onChange={handleShowStl}
                name="showStl"
              />
            }
            label="Show STL"
          />
          <Stack direction={"row"} spacing={2}>
            <Button variant="contained" onClick={onClickSaveCase}>
              Save to Cloud
            </Button>
            <Button variant="contained" onClick={downloadCaseZip}>
              Download to Local
            </Button>
            <input
              type="file"
              id="openCaseZip"
              accept=".zip"
              name="Zip file"
              ref={openCaseZipFile}
              style={{ display: "none" }}
              onChange={openCaseZip}
            />
            <Button
              variant="contained"
              onClick={async () => {
                openCaseZipFile.current.click()
              }}
            >
              Open Case Zip ...
            </Button>
          </Stack>
          <Divider />
          <WeUploadCaseZip />
          <Button onClick={openCaseNewTab}>Open case in new tab</Button>
          <Divider />
          <FormControlLabel
            control={
              <Switch
                checked={switchGum}
                onChange={onSwitchGum}
                name="switchGum"
              />
            }
            label="Switch Gum"
          />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onClickSaveScanStl}>
              Download scan stl
            </Button>
            <Button variant="contained" onClick={onClickSaveTeethStl}>
              Download teeth stl
            </Button>
            <Button variant="contained" onClick={onClickSaveArchStl}>
              Download arch stl
            </Button>
          </Stack>
          <Divider />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onClickNextToothShape}>
              Next Tooth Shape
            </Button>
            <Button variant="contained" onClick={onClickUDToothShape}>
              UD Tooth Shape
            </Button>
            <Button variant="contained" onClick={onClickTemplateToothShape}>
              Template Tooth Shape
            </Button>
            <Button variant="contained" onClick={onClickShowOrgTemplate}>
              Show Template
            </Button>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography> Template Zip: </Typography>
            <input
              style={{ display: "inline-block" }}
              type="file"
              accept=".zip"
              name="Zip file"
              id="templateZip"
              onChange={onClickUploadTeethTemplate}
            />
          </Stack>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showProgressDlg}
          >
            <Box sx={{ width: "40%" }}>
              {progressMsg}
              <LinearProgress />
            </Box>{" "}
          </Backdrop>
        </Stack>
      }
    />
  )
}
