import { useEffect, useState } from "react"

import {
  Paper,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material"

import StartCaseIcon from "@mui/icons-material/StartOutlined"
import MeshEditIcon from "@mui/icons-material/CategoryOutlined"
import ChairsideIcon from "@mui/icons-material/QuickreplyOutlined"
import QuickPlanIcon from "@mui/icons-material/DocumentScannerOutlined"
import QuickStagingIcon from "@mui/icons-material/RectangleOutlined"
import QuickAuxIcon from "@mui/icons-material/AttachmentOutlined"
import ReviewModIcon from "@mui/icons-material/TouchAppOutlined"
import WeSetupIcon from "@mui/icons-material/Settings"
import WeStagingIcon from "@mui/icons-material/RouteOutlined"
import WeAttIcon from "@mui/icons-material/AttachmentOutlined"
import RxIcon from "@mui/icons-material/BookOutlined"
import OrderIcon from "@mui/icons-material/AddShoppingCartOutlined"
import WePrintingIcon from "@mui/icons-material/Print"
import WeTestIcon from "@mui/icons-material/PestControl"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setCurRightPage } from "../udTreatSlice"
import { useTheme } from "@mui/material/styles"
import { EArchType, toothMovement, wasmModule } from "@/gluelayer"

import NewAuxIcon from "../assets/right/attachment.svg"
import NewArchIcon from "../assets/right/arch_adjustment.svg"
import NewVerticalIcon from "../assets/right/vertical_adjustment.svg"

import {
  ArchAdjustmentIcon,
  AuxIcon,
  VerticalAdjustmentIcon,
} from "../top/RightToolBarIcon"
import { ReactSVG } from "react-svg"

export function RightToolBar(props: any) {
  const theme = useTheme()
  const { curCaseMode, curRightPage, switchViewEdit, ucloud1_1 } =
    useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const dispatch = useAppDispatch()
  const [fastsmartAlert, setFastsmartAlert] = useState(false)
  const { curArch, openHistorySplitView } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const [isSingleArch, setIsSingleArch] = useState(false)
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    if (nextTool === "fastsmart" || nextTool === "finetune") {
      const hasUpper = wasmModule.getArchModel(EArchType.UpArch)
      const hasLower = wasmModule.getArchModel(EArchType.LowArch)

      if (hasUpper && hasLower && curArch === "all") {
        setFastsmartAlert(true)
        return
      }
    }

    dispatch(setCurRightPage(nextTool))

    if (!wasmModule.isInit) return
    if (nextTool === "fastsmart") {
      toothMovement.switchModuleFineTuneCircle(false)
      toothMovement.switchModuleFastSmartCircle(true)
    } else if (nextTool === "finetune") {
      toothMovement.switchModuleFastSmartCircle(false)
      toothMovement.switchModuleFineTuneCircle(true)
    } else {
      toothMovement.switchModuleFineTuneCircle(false)
      toothMovement.switchModuleFastSmartCircle(false)
    }
  }

  useEffect(() => {
    dispatch(setCurRightPage(""))

    if (!switchViewEdit && wasmModule.isInit) {
      toothMovement.switchModuleFineTuneCircle(false)
      toothMovement.switchModuleFastSmartCircle(false)
    }
  }, [switchViewEdit])
  const ChairsideBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curRightPage}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="Chairside Setup" placement="left">
          <ToggleButton value="Chairside Setup">
            <ChairsideIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const SmartRxBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curRightPage}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="Smart Rx" placement="left">
          <ToggleButton value="SmartRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const UDPlanBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curRightPage}
        exclusive
        onChange={handleChange}
      >
        <Divider sx={{ height: "2px", backgroundColor: "darkcyan" }} />
        <Tooltip title="UD Setup" placement="left">
          <ToggleButton value="UDSetup">
            <QuickPlanIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Staging" placement="left">
          <ToggleButton value="UDStaging">
            <QuickStagingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Auxillary" placement="left">
          <ToggleButton value="UDAux">
            <QuickAuxIcon />
          </ToggleButton>
        </Tooltip>
        <Divider sx={{ height: "2px", backgroundColor: "darkcyan" }} />
        {/* 
        <Tooltip title="Smart Rx" placement="left">
          <ToggleButton value="SmartRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>*/}
        <Tooltip title="Retainer Rx" placement="left">
          <ToggleButton value="RetainerRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Quick Rx" placement="left">
          <ToggleButton value="QuickRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Order" placement="left">
          <ToggleButton value="Order">
            <OrderIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const ReviewModBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curRightPage}
        exclusive
        onChange={handleChange}
      >
        <ToggleButtonGroup
          orientation="vertical"
          value={curRightPage}
          exclusive
          onChange={handleChange}
        >
          <Tooltip title="Smart Rx" placement="left">
            <ToggleButton value="SmartRx">
              <RxIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Tooltip title="Review & Mod." placement="left">
          <ToggleButton value="ReviewMod">
            <ReviewModIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Order" placement="left">
          <ToggleButton value="Order">
            <OrderIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const WeDesignBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curRightPage}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="UD Shape Edit" placement="left">
          <ToggleButton value="UDMeshEdit">
            <MeshEditIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Setup" placement="left">
          <ToggleButton value="UDSetup">
            <QuickPlanIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Staging" placement="left">
          <ToggleButton value="UDStaging">
            <QuickStagingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Auxillary" placement="left">
          <ToggleButton value="UDAux">
            <QuickAuxIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeSetup" placement="left">
          <ToggleButton value="WeSetup">
            <WeSetupIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeStaging" placement="left">
          <ToggleButton value="WeStaging">
            <WeStagingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeAux" placement="left">
          <ToggleButton value="WeAux">
            <WeAttIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WePrinting" placement="left">
          <ToggleButton value="WePrinting">
            <WePrintingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeTest" placement="left">
          <ToggleButton value="WeTest">
            <WeTestIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }

  const RightToolBar_ucloud2 = () => {
    return (
      <Paper
        sx={{
          right: 16,
          top: 98,
          backgroundColor: theme.transBkColor.light,
          maxHeight: `calc(100% - 114px)`,
          overflow: "auto",
          "::-webkit-scrollbar": {
            width: "1px",
          },
          position: "absolute",
        }}
        elevation={6}
      >
        <Stack direction="column">
          {curCaseMode == "NewCase" ? (
            <></>
          ) : curCaseMode == "UDPlan" ? (
            <UDPlanBtns />
          ) : curCaseMode == "UDModi" ? (
            <ReviewModBtns />
          ) : curCaseMode == "UDView" ? (
            <></>
          ) : curCaseMode == "WeDesign" ? (
            <WeDesignBtns />
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
    )
  }

  const RightToolBar_ucloud1_1 = () => {
    return (
      !openHistorySplitView && (
        <Paper
          sx={{
            right: 16,
            top: 98,
            backgroundColor: theme.transBkColor.light,
            maxHeight: `calc(100% - 114px)`,
            overflow: "auto",
            "::-webkit-scrollbar": {
              width: "1px",
            },
            position: "absolute",
          borderRadius: "8px",
          }}
          elevation={6}
        >
          {switchViewEdit &&
            caseDetail.caseDisposition !== "ATTENTION_NEEDED" && (
              <ToggleButtonGroup
                orientation="vertical"
                value={curRightPage}
                exclusive
                onChange={handleChange}
              >
                <Tooltip title="UD Auxillary" placement="left">
                  <ToggleButton
                  value="UDAux"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                    <ReactSVG
                      src={NewAuxIcon}
                      afterInjection={(svg) => {
                        const path = svg.getElementsByTagName("path")[0]
                        if (curRightPage === "UDAux") {
                          path?.setAttribute("fill", "#215ECD")
                          path?.setAttribute("fill-opacity", "1")
                        } else {
                          path?.setAttribute("fill", "black")
                          path?.setAttribute("fill-opacity", "0.56")
                        }
                      }}
                    ></ReactSVG>
                  </ToggleButton>
                </Tooltip>

                <Tooltip title="Arch Adjustment" placement="left">
                  <ToggleButton
                  value="fastsmart"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                    <ReactSVG
                      src={NewArchIcon}
                      afterInjection={(svg) => {
                        const path = svg.getElementsByTagName("path")[0]
                        if (curRightPage === "fastsmart") {
                          path?.setAttribute("fill", "#215ECD")
                          path?.setAttribute("fill-opacity", "1")
                        } else {
                          path?.setAttribute("fill", "black")
                          path?.setAttribute("fill-opacity", "0.56")
                        }
                      }}
                    ></ReactSVG>
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Vertical Adjustment" placement="left">
                  <ToggleButton
                  value="finetune"
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    "&:hover": {
                      borderRadius: "8px",
                    },
                    "&.Mui-selected": {
                      borderRadius: "8px",
                    },
                  }}
                >
                    <ReactSVG
                      src={NewVerticalIcon}
                      afterInjection={(svg) => {
                        const path = svg.getElementsByTagName("path")[0]
                        if (curRightPage === "finetune") {
                          path?.setAttribute("fill", "#215ECD")
                          path?.setAttribute("fill-opacity", "1")
                        } else {
                          path?.setAttribute("fill", "black")
                          path?.setAttribute("fill-opacity", "0.56")
                        }
                      }}
                    ></ReactSVG>
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            )}
          <Dialog open={fastsmartAlert}>
            {/* <DialogTitle></DialogTitle> */}
            <DialogContent>
              Please select a single arch to use this function.
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFastsmartAlert(false)
                }}
                sx={{
                  textTransform: "uppercase",
                }}
                size="medium"
                variant="contained"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      )
    )
  }

  return ucloud1_1 || curCaseMode === "cloud1_1" ? (
    <RightToolBar_ucloud1_1 />
  ) : (
    <RightToolBar_ucloud2 />
  )
}
