import { useEffect, useState } from "react"
import { Divider, Grid, Stack, Typography } from "@mui/material"
import { FormControlLabel, Switch } from "@mui/material"
import { ArchformType, gdsPlusSetup, stagingManager } from "@/gluelayer"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup, setParamValue } from "@/UDTreat/udSetupSlice"
import {
  FlowBtnGroup,
  FlowButton,
  FlowNameSliderGrid,
  FlowSliders,
} from "../../custom/FlowCustom"
import { setUpdateSetup, setUpdateStaging } from "../../udTreatSlice"
import { UDSetupStatus } from "@/UDTreat/UDesign/UDSetupStatus"
import { setShowArchform } from "@/UDTreat/udSetupSlice"

export const WeSetupArchform = (props) => {
  const { Params, disabled, showArchform } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const dispatch = useAppDispatch()

  const archformBtns = [
    { label: "Natural", name: "ShapeNatural" },
    { label: "Tapered", name: "ShapeTapered" },
    { label: "Ovoid", name: "ShapeOvoid" },
    { label: "Square", name: "ShapeSquare" },
  ]

  const archformSliders = [
    { ...Params.ArchformAnt, step: 0.1, min: 20, max: 40, delta: [0.5, 2] },
    {
      ...Params.ArchformMid,
      step: 0.002,
      min: 0.786,
      max: 0.866,
      delta: [0.002, 0.01],
    },
    { ...Params.ArchformPost, step: 0.1, min: 40, max: 60, delta: [0.5, 2] },
    {
      ...Params.ArchformExpLeft,
      step: 0.02,
      min: 0.9,
      max: 1.1,
      delta: [0.02, 0.1],
    },
    {
      ...Params.ArchformExpRight,
      step: 0.02,
      min: 0.9,
      max: 1.1,
      delta: [0.02, -0.1],
    },
  ]

  const archShiftSliders = [
    { ...Params.JawMoveLR, step: 0.1, min: -3, max: 3, delta: [0.2, 1] },
    { ...Params.JawMoveMD, step: 0.1, min: -3, max: 3, delta: [0.2, 1] },
    { ...Params.JawMoveUD, step: 0.1, min: -3, max: 3, delta: [0.2, 1] },
  ]

  useEffect(() => {
    console.log("mount Archform")
    dispatch(updateAutoSetup())
    return () => {
      console.log("unmount Archform")
    }
  }, [])

  // SetShowArchform doen'st work correctly so let's disable it.
  /* 
  useEffect(() => {
    console.log("Show archform in 3D")
    gdsPlusSetup.SetShowArchform(showArchform)
  }, [showArchform]) 
  */

  const onShowArchformChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShowArchform(event.target.checked))
  }

  const onSliderChange = (name: string, value: number) => {
    // stagingManager.setEndNodeIndex()
    const payload = { name, value }
    dispatch(setParamValue(payload))
  }

  const onSliderChangeCommitted = (name: string, value: number) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamValue(name, value)
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  const onClickResetArchShift = () => {
    gdsPlusSetup.ResetArchShift()
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  const onButtonClickShape = (btnName: string) => {
    console.log(btnName, "clicked")
    let type = ArchformType.Natural
    switch (btnName) {
      case "ShapeNatural":
        type = ArchformType.Natural
        break
      case "ShapeTapered":
        type = ArchformType.Tapered
        break
      case "ShapeOvoid":
        type = ArchformType.Ovoid
        break
      case "ShapeSquare":
        type = ArchformType.Square
        break
    }
    gdsPlusSetup.SetArchformTypeByID(type, () => ({}))
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  return (
    <Stack direction="column" spacing={1}>
      {/*<UDSetupStatus /> */}
      <Stack direction="row" alignItems="center" spacing={20}>
        <Typography variant="body1">Archform</Typography>
        {/* 
        <FormControlLabel
          control={
            <Switch
              disabled={disabled}
              checked={showArchform}
              onChange={onShowArchformChange}
              defaultChecked
            />
          }
          label="Show"
        />*/}
      </Stack>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={archformBtns}
        onButtonClick={onButtonClickShape}
      />
      <FlowSliders
        params={archformSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <Divider />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="body1">Arch Shift</Typography>
        </Grid>
        <Grid item xs={3}>
          <FlowButton onClick={onClickResetArchShift}>Reset</FlowButton>
        </Grid>
      </Grid>
      <FlowSliders
        params={archShiftSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
    </Stack>
  )
}
