import { useEffect, useState, useRef } from "react"
import { gdsPlusSetup, wasmModule } from "@/gluelayer"
import { useDispatch } from "react-redux"
import {
  Stack,
  Button,
  Box,
  Typography,
  Divider,
  Paper,
  Switch,
} from "@mui/material"
import { caseManagement } from "@/gluelayer"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"

export const CourseCheckTable = (props) => {
  const headNames = ["T", "x", "y", "z", "rx", "ry", "rz"]
  const [rows, setRows] = useState([])
  useEffect(() => {
    if (props.resultStr === "") return
    const teethMove = JSON.parse(props.resultStr)
    let allRows = []
    teethMove.map((move) => {
      let row = []
      row.push(["T" + move["tid"], "white"])
      row.push([move["x"].toFixed(2), "white"])
      row.push([move["y"].toFixed(2), "white"])
      row.push([move["z"].toFixed(2), "white"])
      row.push([move["rx"].toFixed(2), "white"])
      row.push([move["ry"].toFixed(2), "white"])
      row.push([move["rz"].toFixed(2), "white"])
      allRows.push(row)
    })
    setRows(allRows)
  }, [props.resultStr])
  return (
    <TableContainer sx={{ padding: 1 }} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headNames.map((name, key) => (
              <TableCell align="right" key={key}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            return (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {row.map((valueColor, idx) => (
                  <TableCell
                    sx={{ backgroundColor: valueColor[1] as string }}
                    key={idx}
                    component="th"
                    align="right"
                    scope="row"
                  >
                    {valueColor[0]}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const WeRetouch = (props) => {
  const dispatch = useDispatch()
  const upperFile = useRef(null)
  const lowerFile = useRef(null)
  const [resultStr, setResultStr] = useState("")
  const [showScan, setShowScan] = useState(true)

  const onClickRunRetouchV2 = (e) => {
    const { files } = e.target
    if (files && files.length) {
      caseManagement.loadPrevCaseFromZip(files[0]).then(() => {
        gdsPlusSetup.RunRetouchV2()
      })
    }
  }
  const onClickUpperStl = (e) => {
    const { files } = e.target
    if (files && files.length) {
      caseManagement.uploadCourseCheckScan(files[0], true).then(() => {
        console.log("Upper stl is uploaded! ")
        gdsPlusSetup.ShowCourseCheckScans(showScan)
      })
    }
  }
  const onClickLowerStl = (e) => {
    const { files } = e.target
    if (files && files.length) {
      caseManagement.uploadCourseCheckScan(files[0], false).then(() => {
        console.log("Lower stl is uploaded! ")
        gdsPlusSetup.ShowCourseCheckScans(showScan)
      })
    }
  }
  const onClickUploadSTL = (isUpper) => {
    if (isUpper) upperFile.current.click()
    else lowerFile.current.click()
    console.log(isUpper)
  }
  const runMCC = () => {
    gdsPlusSetup.RunCourseCheck()
    const result = gdsPlusSetup.GetCourseCheckResult()
    console.log(result)
    setResultStr(result)
  }

  const handleShowScan = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowScan(event.target.checked)
    gdsPlusSetup.ShowCourseCheckScans(event.target.checked)
  }

  useEffect(() => {
    if (!wasmModule.isInit) return
    const result = gdsPlusSetup.GetCourseCheckResult()
    console.log(result)
    setResultStr(result)
  }, [])

  return (
    <Stack direction={"column"} spacing={1}>
      <Stack direction={"row"}>
        <Typography variant="h6"> Load prev case & Run</Typography>
        <input
          style={{ display: "inline-block" }}
          type="file"
          accept=".zip"
          name="Zip file"
          id="Load prev case"
          onChange={onClickRunRetouchV2}
        />
      </Stack>
      <Divider />
      <Stack direction={"row"} spacing={1}>
        <Typography variant="h6">Course Check, Show Scan</Typography>
        <Switch checked={showScan} onChange={handleShowScan} name="showScan" />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <input
          type="file"
          id="upperFileInput"
          accept=".stl, .mtc"
          ref={upperFile}
          style={{ display: "none" }}
          onChange={onClickUpperStl}
        />
        <Button
          variant="contained"
          onClick={() => {
            onClickUploadSTL(true)
          }}
        >
          Upper STL/MTC
        </Button>
        <input
          type="file"
          id="upperFileInput"
          accept=".stl, .mtc"
          ref={lowerFile}
          style={{ display: "none" }}
          onChange={onClickLowerStl}
        />
        <Button
          variant="contained"
          onClick={() => {
            onClickUploadSTL(false)
          }}
        >
          Lower STL/MTC
        </Button>
        <Button variant="contained" onClick={runMCC}>
          Run Course Check
        </Button>
      </Stack>
      <CourseCheckTable resultStr={resultStr} />
    </Stack>
  )
}
