import { wasmModule } from "../wasm/wasmModule";

import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";
export interface IToothPercentInfo {
  enable: boolean
  angle: number
  curStepIndex: number
  dist: number
  newStepNum: number
  percent: number
  prevKFPercent: number
  tID: number
  moveVec: number[]
}

class StagPercentage {
  private _stageInstance
  private _getStageInstance() {
    if (!wasmModule.isInit) {
      return null
    }
    if (this._stageInstance) return this._stageInstance
    wasmModule.moduleManager.SwitchStagingPercent(true)
    this._stageInstance = wasmModule.moduleManager.GetStagingPercent()
    this._stageInstance.Init()
    // console.log('StagePercentInstance:', this._stageInstance);
    return this._stageInstance
  }

  update() {
    const percentModule = this._getStageInstance()
    if (!percentModule) return false
    percentModule.Init()
    return true
  }

  getKeyFrameSteps(isUpper: boolean) {
    const percentModule = this._getStageInstance()
    if (!percentModule) return []
    const steps = percentModule.GetKeyFrameSteps(isUpper)
    let KFSteps = []
    if (steps) {
      for (let i = 0; i < steps.size(); i++) {
        KFSteps.push(steps.get(i))
      }
    }
    return KFSteps
  }

  getTeethPercentInfo(isUpper: boolean, curStep: number) {
    const percentModule = this._getStageInstance()
    if (!percentModule) return null
    const index = isUpper ? 0 : 16
    const percentData: Record<number, IToothPercentInfo> = {}
    for (let tID = 1 + index; tID < 17 + index; tID++) {
      try {
        const percentInfo = percentModule.GetToothMoveForJs(tID, curStep)
        if (percentInfo) {
          percentData[tID] = percentInfo as IToothPercentInfo
          if (percentData[tID].percent > 0.99) percentData[tID].percent = 1
          const moveVec = percentModule.GetToothMoveVec(tID)
          if (moveVec) {
            const myArray: number[] = []
            for (let i = 0; i < moveVec.size(); i++) {
              myArray.push(moveVec.get(i))
            }
            percentData[tID].moveVec = myArray
          }
        }
      } catch (e) {
        percentData[tID] = {
          enable: false,
          angle: 0,
          curStepIndex: curStep,
          dist: 0,
          newStepNum: 0,
          percent: 0,
          prevKFPercent: 0,
          tID: tID,
          moveVec: [],
        }
      }
    }
    return percentData
  }

  setToothMove(tID: number, percent: number) {
    const percentModule = this._getStageInstance()
    if (!percentModule) return
    percentModule.SetToothMove(tID, percent)
  }

  addOrUpdateKeyFrame(
    iJaw: number,
    stepIndex: number,
    noBackMovement: boolean,
  ) {
    const percentModule = this._getStageInstance()
    if (!percentModule) return
    percentModule.AddOrUpdateKeyFrame(iJaw, stepIndex, noBackMovement)
  }

  close() {
    wasmModule.moduleManager.SwitchStagingPercent(false)
    this._stageInstance = null
  }
}

export const stagingPercent = new StagPercentage();
