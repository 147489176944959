import { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material"
import JSZip from "jszip"
import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"

import {
  caseManagement,
  wasmModule,
  stageControl,
  attachmentModule,
  resourcesSynchronization,
  stagingManager,
  EArchType,
} from "@/gluelayer"
import {
  fetchFileList,
  fetchFileZips,
  fetchRefinementJsonFile,
  getCaseInfoApi,
  getTreatmentInfos,
  lockCaseApi,
  unlockCaseApi,
  submitRefinementFiles,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { preview } from "@/gluelayer"
import {
  resetCase,
  setIsShowProgress,
  setProgressMsg,
  setRefinementReady,
} from "@/core/app/slices/clinical/clinicalSlice"
import {
  setCurCaseMode,
  setCurLeftPage,
  setHasUploadSTLs,
  setIsCasePreview,
  setReportData,
  setStageDataLen,
  setSwitchViewEdit,
  setStageShowType,
  setOpenHistorySplitView,
} from "./udTreatSlice"
import { fetchClinicalSettings } from "@/core/app/slices/user/userApis"
import { downloadFiles } from "@/core/app/slices/v2/v2apis"
import { ZipTypes } from "@/core/app/slices/v2/v2.service"
import { setPlanList } from "./udTreatSlice"

import { refinementModule } from "@/gluelayer/core/modules/refinement"
import { navigateTo } from "@/modules/Patient/utils"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { setSingleArchBool } from "./udTreatSlice"

export const UDOpenCase = ({ open, onClose, switchEditCallBack }) => {
  const { caseId, patientId } = useParams()
  return (
    <UDOpenCaseId
      caseId={caseId}
      patientId={patientId}
      open={open}
      onClose={onClose}
    ></UDOpenCaseId>
  )
}
export const UDOpenCaseCaseId = ({ caseId, open, onClose }) => {
  const { patientId } = useParams()
  return (
    <UDOpenCaseId
      caseId={caseId}
      patientId={patientId}
      open={open}
      onClose={onClose}
    ></UDOpenCaseId>
  )
}

export const UDOpenCaseId = ({ caseId, patientId, open, onClose }) => {
  const theme = useTheme()

  //   const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const {
    fileListReady,
    isLoading,
    zipList,
    zipNames,
    refinementFiles,
    isShowProgress,
    ProgressMsg,
  } = useAppSelector((state: RootState) => state.clinicalService)

  const { curCaseMode, isCasePreview } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const [alert, setAlert] = useState(false)
  const navigate = useMyNavigation()
  const location = useLocation()

  // Get Treatment Infos and zip file names
  useEffect(() => {
    console.log("getTreatmentInfos ::")
    dispatch(setCurCaseMode("WeDesign"))
    dispatch(setCurLeftPage(""))
    stagingManager.clearWasmStageData()
    dispatch(setStageShowType(""))
    dispatch(setOpenHistorySplitView(false))
    caseManagement.closeCase()
    dispatch(resetCase())
    dispatch(setSwitchViewEdit(false))

    dispatch(setIsCasePreview(true)) // default is preview only
    setAlert(false)
    // Get treatment infos
    dispatch(getTreatmentInfos({ patientId }))

    // Get zip file names
    dispatch(
      fetchFileList({
        patientId,
        caseId,
        fileNames: ["bulk0", "bulk1", "bulk2", "bulk10", "photo", "raw"],
      }),
    )
    dispatch(fetchClinicalSettings())

    dispatch(setRefinementReady(false))
    dispatch(setIsShowProgress(true))
    dispatch(setProgressMsg("Start download case..."))

    return () => {
      dispatch(resetCase())
      dispatch(setIsShowProgress(false))
    }
  }, [])

  // Get zip files (Blobs)
  useEffect(() => {
    if (zipNames === null) {
      return
    }

    if (zipNames.length) {
      dispatch(
        fetchFileZips({
          orgId: "",
          patientId,
          caseId,
          zipNames,
        }),
      )

      if (refinementFiles.length) {
        dispatch(
          fetchRefinementJsonFile({
            orgId: "",
            patientId,
            caseId,
            refinementFiles,
          }),
        )
      }

      //caseManagement.openCaseOrSwitchCanvas(document.getElementById("canvas") as HTMLCanvasElement,"/public/testdata/shaoguangyu.zip",
      //() => {
      //  setIsCaseLoading(false);
      //})

      dispatch(setIsShowProgress(true))
      dispatch(setProgressMsg("Download case files ..."))
    } else {
      // zero files, so it's empty case
      console.log("No zip files, empty case! ")
      dispatch(setCurCaseMode("NewCase"))
      // dispatch(setCurLeftPage("createcase"))
      dispatch(setIsShowProgress(false))
    }
  }, [zipNames])

  useEffect(() => {
    const bgColor =
      theme.palette.mode === "dark"
        ? [128 / 255, 128 / 255, 128 / 255]
        : [66 / 255, 95 / 255, 107 / 255]

    if (wasmModule.isInit)
      // caseManagement.SetBackgroundGradient("F8F8FF", "#DCDCDC")
      // caseManagement.setBackgroundColor(bgColor[0], bgColor[1], bgColor[2])
      caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
  }, [theme])

  useEffect(() => {
    if (isLoading === "failed" && isShowProgress) {
      console.log("Something wrong for clincal service...")
      dispatch(setProgressMsg("Something is wrong while loading case"))
      dispatch(setIsShowProgress(false))
      dispatch(setCurCaseMode("NewCase"))
    }
  }, [isLoading])

  //   const checkIsCaseFull = async (zipList: Record<string, Blob>) => {
  //     // const blob1 = zipList["bulk1"];
  //     // console.log("XY: Blob1 check - Exists:", !!blob1, "Size:", blob1?.size);

  //     const blob2 = zipList["bulk2"];
  //     console.log("XY: Blob2 check - Exists:", !!blob2, "Size:", blob2?.size);

  //     // 只处理 bulk2
  //     if (blob2) {
  //         console.log("XY: Processing bulk2 file...");
  //         // 将 Blob 转换为 ArrayBuffer
  //         try {
  //             const arrayBuffer = await blob2.arrayBuffer();
  //             console.log("XY: Converted Blob to ArrayBuffer for bulk2, array buffer size is", arrayBuffer.byteLength);

  //             // 创建 JSZip 实例
  //             const zip = new JSZip();

  //             // 加载 ZIP 文件内容
  //             const loadedZip = await zip.loadAsync(arrayBuffer);
  //             console.log("XY: ZIP file loaded for bulk2");

  //             // 输出 ZIP 文件中的所有文件和文件名
  //             console.log("XY: Files in ZIP:");
  //             const fileNames = Object.keys(loadedZip.files);
  //             if (fileNames.length === 0) {
  //                 console.log("XY: No files found in the ZIP.");
  //             } else {
  //                 console.log("XY: files found in the ZIP. file length is:", fileNames.length, "file names are:", fileNames);
  //                 fileNames.forEach(file => {
  //                     console.log(`- ${file}`);
  //                 });
  //             }

  //             // 检查是否存在指定文件 "modulecontrol.data"
  //             const targetFileName = 'Setting Data2/ModuleControl.data';
  //             const entry = loadedZip.files[targetFileName];

  //             if (entry && !entry.dir) {
  //                 console.log("XY: Found target file:", targetFileName);

  //                 // 读取文件内容并转换为字符串
  //                 const data = await entry.async('string');
  //                 console.log("XY: Loaded data from", targetFileName);

  //                 // 分割文件内容
  //                 const lines = data.split('\n');
  //                 console.log("XY: Split data into lines. Number of lines:", lines.length);

  //                 // 读取文件中的信息
  //                 const versionId = parseInt(lines[0]);
  //                 const numberOfProcessModule = parseInt(lines[1]);
  //                 console.log("XY: versionId =", versionId, "numberOfProcessModule =", numberOfProcessModule);

  //                 if (numberOfProcessModule === 0) {
  //                     console.log("XY: No process modules found, returning false");
  //                     return false;
  //                 }

  //                 let isGotoSetupDirect = false;

  //                 for (let i = 0; i < numberOfProcessModule; i++) {
  //                     const moduleName = lines[2 + i * 4];
  //                     const archType = parseInt(lines[3 + i * 4]);
  //                     const isClosedNormally = lines[4 + i * 4] === 'true';

  //                     console.log(`XY: Module ${i} - Name: ${moduleName}, ArchType: ${archType}, ClosedNormally: ${isClosedNormally}`);

  //                     // 如果是最后一个模块，设置 isGotoSetupDirect
  //                     if (i === numberOfProcessModule - 1) {
  //                         isGotoSetupDirect = isClosedNormally;
  //                         console.log("XY: isGotoSetupDirect set to", isGotoSetupDirect);
  //                     }
  //                 }

  //                 console.log("XY: Final result of isGotoSetupDirect:", isGotoSetupDirect);
  //                 return isGotoSetupDirect;
  //             } else {
  //                 console.log(`XY: ${targetFileName} not found in the ZIP file.`);
  //             }
  //         } catch (error) {
  //             console.error("XY: Error reading ZIP file:", error);
  //         }
  //     } else {
  //         console.log("XY: bulk2 does not exist in zipList.");
  //     }

  //     console.log("XY: Completed checkIsCaseFull without finding target file or fulfilling conditions");
  // };

  const checkIsCaseFull = async (zipList: Record<string, Blob>) => {
    const blob2 = zipList["bulk2"]
    console.log("XY: Blob2 check - Exists:", !!blob2, "Size:", blob2?.size)

    if (blob2) {
      console.log("XY: Processing bulk2 file...")
      const arrayBuffer = await blob2.arrayBuffer()
      const zip = new JSZip()
      const loadedZip = await zip.loadAsync(arrayBuffer)
      console.log("XY: ZIP file loaded for bulk2")

      // 读取 ModuleControl.data 文件
      const targetFileName = "Setting Data2/ModuleControl.data"
      const entry = loadedZip.files[targetFileName]

      if (entry && !entry.dir) {
        const data = await entry.async("string")
        const lines = data.split("\n")

        let newVersionFile = false
        let numberOfProcessModule = 0
        let closedModuleCount = 0

        // 检查文件内容
        if (lines.length > 2 && /^\d+$/.test(lines[2].trim())) {
          newVersionFile = true
          numberOfProcessModule = parseInt(lines[2].trim())
        } else {
          numberOfProcessModule = parseInt(lines[1].trim())
        }

        if (numberOfProcessModule === 0) {
          return false // 没有模块
        }

        console.log("XY: numberOfProcessModule is ", numberOfProcessModule)

        // 处理模块信息
        for (let i = 0; i < numberOfProcessModule; i++) {
          // 读取状态行
          const moduleStatusLine = lines[newVersionFile ? 3 + i * 5 : 2 + i * 4]
          console.log(`XY: Module ${i} - Status: ${moduleStatusLine}`)

          // 读取名称行
          const moduleNameLine = lines[newVersionFile ? 4 + i * 5 : 3 + i * 4]
          console.log(`XY: Module ${i} - Name: ${moduleNameLine}`)

          // 读取下一个行（状态值）
          const nextLine = lines[newVersionFile ? 5 + i * 5 : 4 + i * 4]
          console.log(`XY Next Line after Module ${i}: "${nextLine}"`)

          // 解析状态值
          const statusValue = parseInt(nextLine.trim())

          if (statusValue > 0) {
            closedModuleCount++
          }
          console.log(
            "XY: After closedModuleCount is ",
            closedModuleCount,
            " i is ",
            i,
          )

          // 检查是否所有模块都关闭
          if (closedModuleCount === numberOfProcessModule) {
            console.log("XY: IS goto setup")
            return true // 所有模块都关闭
          }

          // 读取额外的行（如果是新版本文件）
          if (newVersionFile) {
            const extraLine1 = lines[6 + i * 5]
            const extraLine2 = lines[7 + i * 5]
            const extraLine3 = lines[8 + i * 5]
            console.log(`XY: Final Line 1 for Module ${i}: "${extraLine1}"`)
            console.log(`XY: Final Line 2 for Module ${i}: "${extraLine2}"`)
            // 处理额外行的逻辑（如果需要）
          } else {
            const extraLine3 = lines[5 + i * 4]
            // 处理额外行的逻辑（如果需要）
          }
        }
      } else {
        console.log(`XY: ${targetFileName} not found in the ZIP file.`)
      }
    } else {
      console.log("XY: bulk2 does not exist in zipList.")
    }
    return false
  }

  const openCasePreview = (zips: Record<string, Blob>) => {
    dispatch(setProgressMsg("Open the case ..."))
    caseManagement.closeCase()
    preview
      .initScanView(
        document.getElementById("canvas") as HTMLCanvasElement,
        zips,
      )
      .then(() => {
        const res = preview.drawScanMtc([0.25, 4])

        const originArchTypeData = resourcesSynchronization.getFileDirectly(
          "ArchType.json",
          "Setting Data2",
          "utf8",
        )
        console.log(
          "🚀 ~ openCasePreview ~ originArchTypeData:",
          originArchTypeData,
        )
        dispatch(
          setHasUploadSTLs([
            res.get("arch_o_u.mtc") !== null,
            res.get("arch_o_l.mtc") !== null,
          ]),
        )
      })
    dispatch(setIsShowProgress(false))
  }

  const openCaseFull = (zipList: Record<string, Blob>) => {
    dispatch(setProgressMsg("Open the case ..."))
    caseManagement.closeCase()
    caseManagement.setCallbackAfterOpencase(() => {
      setPreparation(true)
      caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
      dispatch(setHasUploadSTLs([true, true]))
      // get txplan data
      const txList = caseManagement.getTreatmentPlanList()
      dispatch(setPlanList(txList))
      // get stage len
      const { jointUpKeypoints, jointLowerKeypoints } =
        stageControl.getWasmStageData()
      dispatch(
        setStageDataLen({
          up: jointUpKeypoints.length,
          low: jointLowerKeypoints.length,
        }),
      )
      // get reportData
      getReportFunc()
      // get single arch bool
      const hasUpper = wasmModule.getArchModel(EArchType.UpArch)
      const hasLower = wasmModule.getArchModel(EArchType.LowArch)
      dispatch(setSingleArchBool(hasUpper == null || hasLower == null))
    })

    caseManagement
      .openCase(document.getElementById("canvas") as HTMLCanvasElement, zipList)
      .then((val) => {})
      .catch((error) => {
        //dispatch(setProgressMsg("Error to open case."))
        //setIsCaseLoading(false)
      })
  }
  // get report data
  const getReportFunc = () => {
    const { attachment, toothlist, ipr, firstStage } =
      caseManagement.getAttachmentIPRReport()
    // setAttachStage(firstStage)
    const { lowlist, uplist } = toothlist
    // Determine if the upper and lower jaws have separate attachments
    const obj = {
      up: false,
      down: false,
    }
    for (let i = 0; i < uplist.length; i++) {
      const upAttach = attachment[uplist[i]].attachment.length
      if (upAttach) {
        obj.up = true
        break
      }
    }
    for (let i = 0; i < lowlist.length; i++) {
      const downAttach = attachment[lowlist[i]].attachment.length
      if (downAttach) {
        obj.down = true
        break
      }
    }
    // setAttachmentObj(obj)
    const list = lowlist.concat(uplist)
    dispatch(
      setReportData({
        attachment,
        toothlist: list,
        iprData: ipr,
      }),
    )
  }
  // Open case with zipList
  useEffect(() => {
    if (
      (Object.keys(zipList).length == 6 && refinementFiles.length == 0) ||
      (refinementFiles.length > 0 && Object.keys(zipList).length > 6)
    ) {
      dispatch(setProgressMsg("Check case type..."))
      const zips = zipList as Record<string, Blob>
      const checkFullCase = checkIsCaseFull(zips)

      checkFullCase.then((isFullCase) => {
        console.log("🚀 ~ checkFullCase.then ~ isFullCase:", isFullCase)
        const caseDisposition = caseDetail.caseDisposition
        if (caseDisposition === "FROM_UDESIGN" && !isFullCase) {
          setAlert(true)
          dispatch(setIsShowProgress(false))
          return
        }
        dispatch(setIsCasePreview(!isFullCase)) 

        //SUBMITTED_TO_UASSIST  TREATMENT_IN_PROGRESS

        if (isFullCase) {
          dispatch(setProgressMsg("Open full case ..."))
          openCaseFull(zips)
        } else {
          dispatch(setProgressMsg("Open preview case ..."))
          openCasePreview(zips)
        }
      })
    }
  }, [zipList])
  const [preparation, setPreparation] = useState<boolean>(false)
  // AttachmentStage
  const {
    userService: {
      clinicalSettings: { AttachmentStage },
    },
  } = useAppSelector((state: RootState) => state)
  // attachment stage
  useEffect(() => {
    if (preparation) {
      // set attachment stage
      attachmentModule.setAttachStep(+AttachmentStage)
      // 检查 hash 是否以 #uassit 结尾
      if (location.hash === "#uassist") {
        switchEditCallBack()
      }
    }
  }, [preparation, AttachmentStage])

  return (
    open && (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowProgress}
      >
        <Box sx={{ width: "40%" }}>
          {ProgressMsg}
          <LinearProgress />
        </Box>

        <Dialog
          sx={{
            width: 510,
            height: 330,
            alignSelf: "center",
            justifySelf: "center",
          }}
          open={alert}
        >
          <DialogTitle
            sx={{
              fontsize: "20px",
              fontstyle: "normal",
              fontweight: 500,
              lineheight: "160%",
              letterspacing: " 0.15px",
            }}
          >
            Self-planned cases must complete pre-setup in uDesign 10
          </DialogTitle>
          <DialogContent
            sx={{
              fontsize: "16px",
              fontstyle: "normal",
              fontweight: 400,
              lineheight: "150%",
              letterspacing: "0.15px",
            }}
          >
            To view or modify a self planned case in uDesign Cloud, you must
            first <strong>open the case in uDesign desktop software</strong> and
            complete tooth identification and segmentation.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAlert(false)
                navigate(-1)
              }}
              sx={{
                textTransform: "uppercase",
              }}
              size="medium"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
    )
  )
}
