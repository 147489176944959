import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { moduleMeshEditV2, wasmModule } from "@/gluelayer"
import { Stack, Button, Typography, Checkbox } from "@mui/material"
import { Grid, Slider } from "@mui/material"

export const ToothFAPoint = (props) => {
  const [meshInfos, setMeshInfos] = useState([])
  const [xfmValue, setXfmValue] = useState([])
  const names = ["x", "y", "z", "Rx", "Ry", "Rz"]
  const getMeshInfos = () => {
    const infoStr = moduleMeshEditV2.GetAllMeshInfosStr()
    const meshInfos = JSON.parse(infoStr)
    setMeshInfos(meshInfos)
  }
  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("Open mesh edit module")
    moduleMeshEditV2.SwitchModuleMeshEditV2(true)
    moduleMeshEditV2.SetEditMode(3)
    getMeshInfos()
    return () => {
      console.log("End mesh edit module")
      moduleMeshEditV2.SwitchModuleMeshEditV2(false)
    }
  }, [])
  return (
    <Stack>
      <Typography variant="body1"> Edit FA Point</Typography>
    </Stack>
  )
}
