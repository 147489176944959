import { useEffect } from "react"
import { Grid, Typography } from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup, setParamValue } from "@/UDTreat/udSetupSlice"
import { FlowSliders } from "../custom/FlowCustom"
import { setUpdateSetup, setUpdateStaging } from "../udTreatSlice"
import { stagingManager, gdsPlusSetup } from "@/gluelayer"
import { UDSetupStatus } from "./UDSetupStatus"

export const UDSetupArchform = () => {
  const dispatch = useAppDispatch()
  const { Params, disabled } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  useEffect(() => {
    console.log("mount Archform")
    dispatch(updateAutoSetup())
    // dispatch(setUpdateSetup())
    return () => {
      console.log("unmount Archform")
    }
  }, [])

  const archformSliders = [
    { ...Params.ArchformAnt, step: 0.1, min: 20, max: 40, delta: [0.5, 2] },
    {
      ...Params.ArchformMid,
      step: 0.02,
      min: 0.786,
      max: 0.866,
      delta: [0.02, 0.1],
    },
    { ...Params.ArchformPost, step: 0.1, min: 40, max: 60, delta: [0.5, 2] },
    {
      ...Params.ArchformExpLeft,
      step: 0.02,
      min: 0.9,
      max: 1.1,
      delta: [0.02, 0.1],
    },
    {
      ...Params.ArchformExpRight,
      step: 0.02,
      min: 0.9,
      max: 1.1,
      delta: [0.02, -0.1],
    },
  ]

  const archShiftSliders = [
    { ...Params.JawMoveLR, step: 0.1, min: -3, max: 3, delta: [0.2, 1] },
    { ...Params.JawMoveMD, step: 0.1, min: -3, max: 3, delta: [0.2, 1] },
    { ...Params.JawMoveUD, step: 0.1, min: -3, max: 3, delta: [0.2, 1] },
  ]
  const paramDeltas = [
    [0.2, 0.5], // 0.2mm, 0.5mm
    [0.5, 2], // 0.5%, 2%
    [0.2, 0.5], // 0.2mm, 0.5mm
    [0.01, 0.05], // 1%, 5%,
    [0.01, 0.05], // 1%, 5%,
  ]

  const onSliderChange = (name: string, value: number) => {
    // stagingManager.setEndNodeIndex()
    const payload = { name, value }
    dispatch(setParamValue(payload))
  }

  const onSliderChangeCommitted = (name: string, value: number) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamValue(name, value)
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  return !disabled ? (
    <Grid container alignItems={"center"} spacing={0.25}>
      <Grid item xs={12}>
        <Typography variant="body1">Archform </Typography>
      </Grid>
      <FlowSliders
        params={archformSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      {/* 
      <UDTitlePlusMinus
        title="Anterior"
        deltaSmall={0.1}
        deltaLarge={0.5}
        value="23.3 mm"
      />
      <UDTitlePlusMinus title="Middle" value="23.3 mm" />
      <Grid item xs={12}>
        <Typography variant="body1">Anterior </Typography>
      </Grid>
      <UDTitlePlusMinus title="Overjet" value="23.3 mm" />
      <UDTitlePlusMinus title="Overbite" value="23.3 mm" />
      <UDTitlePlusMinus title="Lower Spee" value="1.2 mm" />
      <Grid item xs={12}>
        <Typography variant="body1">Middle Line </Typography>
      </Grid>
      <UDTitlePlusMinus title="Upper" value="23.3 mm" />
      <UDTitlePlusMinus title="Lower" value="23.3 mm" />
      <Grid item xs={12}>
        <Typography variant="body1">Root Torque </Typography>
      </Grid>
      <UDTitlePlusMinus title="Upper" value="23.3 mm" />
      <UDTitlePlusMinus title="Lower" value="23.3 mm" />
      <Grid display="flex" justifyContent="center" item xs={12}>
        <Button variant="contained">Run ...</Button>
      </Grid>*/}
    </Grid>
  ) : (
    <UDSetupStatus />
  )
}
