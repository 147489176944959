import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { gdsPlusSetup, moduleMeshEditV2, wasmModule } from "@/gluelayer"
import { setLockCaseForEdit } from "../udTreatSlice"
import { RightPage } from "../right/RightPage"
import { FormControl, FormLabel, Radio, RadioGroup } from "@mui/material"
import {
  Stack,
  FormControlLabel,
  Button,
  Typography,
  Checkbox,
} from "@mui/material"
import { UDTabs } from "../custom/UDTabs"
import { MeshExplorer } from "./MeshExplorer"
import { ToothBasisEdit } from "./ToothBasisEdit"
import { ToothFAPoint } from "./ToothFAPoint"

export const UDMeshEdit = (props) => {
  return (
    <RightPage
      title="Mesh Edit V2"
      onClose={props.onClose}
      content={
        <UDTabs
          labels={["Mesh Edit", "Tooth Basis", "Mesh Explorer", "Tooth FA"]}
        >
          <MeshEditV2 />
          <ToothBasisEdit />
          <MeshExplorer />
          <ToothFAPoint />
        </UDTabs>
      }
    />
  )
}

export const MeshEditV2 = (props) => {
  const [showCurToothOnly, setShowCurToothOnly] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("Open mesh edit module")
    moduleMeshEditV2.SwitchModuleMeshEditV2(true)
    moduleMeshEditV2.SetEditMode(0)
    return () => {
      console.log("End mesh edit module")
      moduleMeshEditV2.SwitchModuleMeshEditV2(false)
    }
  }, [])
  const [editMode, setEditMode] = useState("patch")
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var value = (event.target as HTMLInputElement).value
    setEditMode(value)
    moduleMeshEditV2.SetEditMode(value == "patch" ? 0 : 1)
  }
  const onClickSetRadius = (radius) => {
    moduleMeshEditV2.SetPickRadius(radius)
  }
  const onClickUndoRedo = (doUndo) => {
    moduleMeshEditV2.UndoRedo(doUndo)
  }
  const onClickShowOnly = () => {
    const newShowOnly = !showCurToothOnly
    setShowCurToothOnly(newShowOnly)
    moduleMeshEditV2.ShowCurToothMeshOnly(newShowOnly)
  }
  return (
    <Stack spacing={1}>
      <FormControl>
        <FormLabel>Edit Mode</FormLabel>
        <RadioGroup
          row
          name="edit-mode-group"
          value={editMode}
          onChange={handleChange}
        >
          <FormControlLabel value="patch" control={<Radio />} label="Patch" />
          <FormControlLabel value="smooth" control={<Radio />} label="Smooth" />
        </RadioGroup>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox checked={showCurToothOnly} onClick={onClickShowOnly} />
        }
        label="Show Cur Tooth Only"
      />
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          onClick={() => {
            onClickUndoRedo(true)
          }}
        >
          Undo
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClickUndoRedo(false)
          }}
        >
          Redo
        </Button>
      </Stack>
      <Typography variant="body1">Pick Size</Typography>
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          onClick={() => {
            onClickSetRadius(0.5)
          }}
        >
          0.5mm
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClickSetRadius(1)
          }}
        >
          1mm
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClickSetRadius(2)
          }}
        >
          2mm
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClickSetRadius(3)
          }}
        >
          3mm
        </Button>
      </Stack>
    </Stack>
  )
}
