import { FC, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useResizeObserver } from "react-use-observer"
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material"
import {
  EBiteJumpLocationType,
  ENodeType,
  refinement,
  stageControl,
  stagingManager,
  treatView,
} from "@/gluelayer"
import { number } from "prop-types"

import attachmentInstall from "../../assets/bottomToolBar/stage/attachmentInstall.svg"
import attachmentRemove from "../../assets/bottomToolBar/stage/attachmentRemove.svg"
import iprDown from "../../assets/bottomToolBar/stage/iprDown.svg"
import iprUp from "../../assets/bottomToolBar/stage/iprUp.svg"
import refinementTip from "../../assets/bottomToolBar/stage/refinementTip.svg"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import ToolButton from "../Toolbar/toolButton"
import StageArch from "./stageArch"

import { stageBgColor, stageConfig } from "./column"
import { EArchType, NodeStru } from "@/gluelayer";

import "./stageBar.scss"
import { max } from "moment-timezone"
import { Height } from "@mui/icons-material"

import StageDragBar from "./stageDragBar"
import { setIsCasePreview, udTreatSlice } from "@/UDTreat/udTreatSlice"
import { isNodeContains } from "./stageCommon"
import { wasmModule } from "@/gluelayer"


interface retouchType {
  showStage: string // 显示的stage点
  postStage: number // 本段的stage点
  postNum: number // 段数
  currentNum: number // 回传的数字
  cirPoint: boolean // 圆点标记
  iprPoint: boolean // 菱形点标记
  quickPoint: boolean // quick3标记
  passiveAlignerPoint: boolean // passiveAlignerPoint
}
// stage单位长度
const uniNumber = 32
let runStep_up = 0
let runStep_down = 0
let up_current = 0
let down_current = 0
let timer: any = null
let setStepTimer: any = null
// label array
let upLabels = []
let downLabels = []

import { setCurrentStep } from "@/UDTreat/udTreatSlice"
import useLatest from "./useLatest"

const StageBarForCompare: FC = ({
  preparation,
  btnObj,
  setBtnObj,
  isFold,
  setStageObj,
  setIsRefinement,
  isRefinement,
  attachmentObj,
  setRefinementKey,
  refinementKey,
  setStagebarHeight,
  setStageBarWidth,
  maxRefine,
  setMaxRefine,
  attachStage,
  stageBarWidth,
  getTxPlans,
  viewIndex,
  onOff,
  stageMaxWidth,
  showTxTab
}) => {
  const singleStageWidth = 23
  const StageDiv = 4
  const stageMinWidth = 600
  const dispatch = useAppDispatch()
  const { currentStep, updateStaging, updateSetup } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const {
    clinicalSettings: { AttachmentStage },
  } = useAppSelector((state: RootState) => state.userService)
  const { t } = useTranslation("common")
  const [countOfFoldGroup, setCountOfFoldGroup] = useState<number>(5)
  const [actualSingleStageWidth, setActualSingleStageWidth] = useState<number>(singleStageWidth)
  // 设置stageData数据
  const [upList, setUpList] = useState<any[]>([])
  const [downList, setDownList] = useState<any[]>([])

  const [upNodes, setUpNodes] = useState<any[]>([])
  const [downNodes, setDownNodes] = useState<any[]>([])
  // marks
  const [up_step_Marks, setUpStepMarks] = useState([])
  const [down_step_Marks, setDownStepMarks] = useState([])
  // one step unit length
  const [unitLen, setUnitLen] = useState<number>(0)
  // up stage length
  const [upSteps, setUpSteps] = useState<number>(0)
  // down stage length
  const [downSteps, setDownSteps] = useState<number>(0)
  // stageBar type
  const [stageType, setStageType] = useState<string>("less40")
  // useResizeObserver listener width change
  const [resizeObserverRef, resizeObserverEntry] = useResizeObserver()
  const [boxWidth, setBoxWidth] = useState(
    resizeObserverEntry.contentRect?.width || stageMinWidth,
  )
  const [boxHeight, setBoxHeight] = useState(
    resizeObserverEntry.contentRect?.height || stageMinWidth,
  )

  // refinement segmentation data
  const [upRefineData, setUpRefineData] = useState({})
  const [downRefineData, setDownRefineData] = useState({})
  // refinement buttons
  const [refineBtns, setRefineBtns] = useState([])
  // refinement is misalign
  const [isRefinementAlign, setIsRefinementAlign] = useState<boolean>(true)
  const [upRefineNum, setUpRefineNum] = useState<number>(0)
  const [downRefineNum, setDownRefineNum] = useState<number>(0)

  const [dragBarLeft, setDragBarLeft] = useState<number>(0)
  const [stageArchLeft, setStageArchLeft] = useState<number>(0)
  const [stageArchRight, setStageArchRight] = useState<number>(0)

  const stageUpArchRef = useRef(null)
  const stageDownArchRef = useRef(null)
  const stageDragBarRef = useRef(null)
  const stageMaxWidthLatest = useLatest(stageMaxWidth)
  const [upStageCurrentStep, setUpStageCurrentStep] = useState<number>(0)
  const [downStageCurrentStep, setDownStageCurrentStep] = useState<number>(0)
  const [gmPercent, setGmPercent] = useState(0.0);
  const [isGmClicked, setIsGmClicked] = useState(false);
  const [upQuick3Index, setUpQuick3Index] = useState<number>(-1);
  const [downQuick3Index, setDownQuick3Index] = useState<number>(-1);

  //arch load finished
  const stageArchLoadFinished = (archType: number) => {
    setDragBarStep(Math.max(upStageCurrentStep, downStageCurrentStep))
  }

  // get unitLen
  const setUnit = (
    upNum: number,
    downNum: number,
    upData: retouchType[],
    downData: retouchType[],
  ) => {
    let width =
      document.getElementsByClassName("stageBarContainerBottomWrap")[0]
        .offsetWidth - 256
    if (upNum - 1 < 5 && downNum - 1 < 5) {
      setUnitLen(96)
      return
    }
    if (upNum - 1 >= 5 || downNum - 1 >= 5) {
      if (window.screen.width > 1920) {
        width = width - 30
        setUnitLen(
          downNum >= upNum
            ? Math.floor(Math.min(width, 1530) / (downNum - 1))
            : Math.floor(Math.min(width, 1530) / (upNum - 1)),
        )
      } else {
        setUnitLen(
          downNum >= upNum
            ? Math.floor(width / (downNum - 1))
            : Math.floor(width / (upNum - 1)),
        )
      }

      return
    }
  }

  const execSetStepAsync = async () => {
    // 这里可以是异步的操作，例如数据获取或其他异步任务
    // setStageStep(up_current, down_current)

    // console.info("execSetStepAsync: ", up_current, down_current)
    console.info("execSetStepAsync: ", Math.max(up_current, down_current))
    console.info("viewIndex: ", viewIndex)
    treatView.PlayFrame(viewIndex, Math.max(up_current, down_current))

    // stageUpArchRef.current?.setCurrentStep(up_current)
    // stageDownArchRef.current?.setCurrentStep(down_current)
    // setDragBarStep(Math.max(up_current, down_current))
  }

  const dragBarPositionXChanged = (x) => {
    console.info("=====drag bar position x: ", x)
    let stepIndex = stageUpArchRef.current?.getStageStepByPositionX(x)
    if (stepIndex === -1) {
      stepIndex = stageDownArchRef.current?.getStageStepByPositionX(x)
    }
    console.info("=====drag bar step: ", stepIndex)
    if (stepIndex < 0) {
      return
    }

    setDragBarStep(stepIndex)

    updateCurrentStepValue(stepIndex)
    execSetStepAsync()
    setUpStageCurrentStep(up_current)
    setDownStageCurrentStep(down_current)
  }

  const dragBarStoped = () => {
    setDragBarStep(Math.max(up_current, down_current))
  }

  const getStageMinX = () => {
    let minX = 0
    if (upNodes.length > 0) {
      minX = stageUpArchRef.current?.getStagePositionX(upNodes.length - 1)
    } else if (downNodes.length > 0) {
      minX = stageDownArchRef.current?.getStagePositionX(downNodes.length - 1)
    }
    return minX
  }

  const getStageMaxX = () => {
    let maxRight = 0
    if (upNodes.length > downNodes.length) {
      maxRight = stageUpArchRef.current?.getStagePositionX(upNodes.length - 1)
    } else {
      maxRight = stageDownArchRef.current?.getStagePositionX(
        downNodes.length - 1,
      )
    }
    return maxRight
  }
  // useEffect(() => {
  //   if (updateCurrentStepValue(currentStep)) {
  //     execSetStepAsync()
  //   }
  // }, [currentStep])

  // useEffect(() => {
  //   const data = getWasmStageData()
  //   updateAll(data)
  // }, [updateStaging])
  // useEffect(() => {
  //   console.log("updateSetup!", updateSetup)
  //   setTimeout(() => {
  //     setBtnObj({
  //       type: "icEnd",
  //       isBool: !btnObj.isBool,
  //     })
  //   }, 100) // dealy for 0.5s then click "icEnd" */
  // }, [updateSetup])

  const updateCurrentStepValue = (stepValue) => {
    let changed = false

    const upStepValue = Math.min(stepValue, upNodes.length - 1)
    if (upStepValue >= 0 && up_current !== upStepValue) {
      up_current = upStepValue
      changed = true
    }

    const downStepValue = Math.min(stepValue, downNodes.length - 1)
    if (downStepValue >= 0 && down_current !== downStepValue) {
      down_current = downStepValue
      changed = true
    }

    return changed
  }

  const updateDefaultStepValue = (archType, stepValue) => {
    let changed = false

    if (archType === 0) {
      const upStepValue = Math.min(stepValue, upNodes.length - 1)
      if (upStepValue >= 0 && up_current !== upStepValue) {
        up_current = upStepValue
        changed = true
      }
    }
    else if (archType === 1) {
      const downStepValue = Math.min(stepValue, downNodes.length - 1)
      if (downStepValue >= 0 && down_current !== downStepValue) {
        down_current = downStepValue
        changed = true
      }
    }

    if (changed) {
      if (archType === 0) {
        setUpStageCurrentStep(up_current)
      }
      else if (archType === 1) {
        setDownStageCurrentStep(down_current)
      }
      setDragBarStep(Math.max(up_current, down_current))
      execSetStepAsync()
    }

    return changed
  }

  const setDragBarStep = (stepIndex) => {
    if (
      stageUpArchRef.current !== undefined &&
      stageDownArchRef.current !== undefined
    ) {
      const upMiddleX = stageUpArchRef.current?.getStagePositionX(stepIndex)
      const downMiddleX = stageDownArchRef.current?.getStagePositionX(stepIndex)
      const maxMiddleX = Math.max(upMiddleX, downMiddleX)
      console.info("=====stage:", stepIndex, "maxMiddleX:", maxMiddleX)
      //stageDragBarRef.current?.resetDragBar()
      setDragBarLeft(maxMiddleX)
    }
  }

  const stepChangeHandler = (archType: number, stageCurrentStep: number) => {
    const max_step = Math.max(upNodes.length - 1, downNodes.length - 1)
    if (max_step < 0) {
      return
    }

    updateCurrentStepValue(stageCurrentStep)
    execSetStepAsync()
    setUpStageCurrentStep(up_current)
    setDownStageCurrentStep(down_current)
    setDragBarStep(Math.max(up_current, down_current))
  }

  //load arch timer set
  const setStepDelay = () => {
    if (setStepTimer !== null) {
      clearTimeout(setStepTimer)
    }

    setStepTimer = setTimeout(() => {
      execSetStepAsync()
    }, 50)
  }

  // play or stop stage
  const playOrStop = () => {
    clearInterval(timer)
    const upLen = upNodes.length - 1
    const downLen = downNodes.length - 1
    runStep_up = upStageCurrentStep
    runStep_down = downStageCurrentStep
    if(runStep_up >= upLen && runStep_down >= downLen) {
      runStep_up = 0
      runStep_down = 0
    }
    else if(runStep_up >= upLen) {
      runStep_up = downStageCurrentStep
      runStep_down = downStageCurrentStep
    }
    else if(runStep_down >= downLen) {
      runStep_up = upStageCurrentStep
      runStep_down = upStageCurrentStep
    }
    // const biteJumpType = stageControl.getBiteJumpType()
    //clear gm button percent
    
    if (btnObj.type === "play") {
      setIsGmClicked(false)
      setGmPercent(0.0)
      treatView.SetBiteJumType(viewIndex, EBiteJumpLocationType.EBiteJumpLocation_Off)
      treatView.UpdateLowerArchActorAfterSetGMType(viewIndex)

      timer = setInterval(() => {
        if (runStep_up <= upLen || runStep_down <= downLen) {
          if (runStep_up <= upLen) {
            //setUpCurrent(runStep_up)
            up_current = runStep_up
            runStep_up++
          }
          if (runStep_down <= downLen) {
            // setDownCurrent(runStep_down)
            if (runStep_down) down_current = runStep_down
            runStep_down++
          }
          setUpStageCurrentStep(up_current)
          setDownStageCurrentStep(down_current)
          setDragBarStep(Math.max(up_current, down_current))
          execSetStepAsync()
        } else {
          runStep_up = 0
          runStep_down = 0
          clearInterval(timer)
          setBtnObj({
            type: "stop",
            isBool: !btnObj.isBool,
          })
          
          //play gm
          //wasmModule.wrapInstance.ShowCollision(false)
          treatView.SetBiteJumType(viewIndex, EBiteJumpLocationType.EBiteJumpLocation_End)
          treatView.PlayGM(viewIndex)
          treatView.UpdateLowerArchActorAfterSetGMType(viewIndex)
        }
      }, 200)
    }
  }
  // switch refinement
  const switchRefinement = (key: string) => {
    stagingManager.showRefinementHistoryRangeType = key
    refinement.setRefinementDisplayRange(key)
    setRefinementKey(key)
  }
  // useEffect(() => {
  //   getMark("up", upList)
  //   getMark("down", downList)
  //   setUnit(upList.length, downList.length, upList, downList)
  // }, [
  //   upList,
  //   downList,
  //   unitLen,
  //   boxWidth,
  //   attachmentObj.up,
  //   attachmentObj.down,
  // ])
  useEffect(() => {
    if (isRefinement && maxRefine) {
      //set refinemet key by default
      const key = `refinement${maxRefine}`
      setRefinementKey(key)
    }
  }, [isRefinement, maxRefine])

  const calcMaxArchWidth = (
    countOfFoldGroup,
    upStageCount,
    downStageCount,
    singleStageWidth,
  ) => {
    let upMaxArchWidth = 0
    let downMaxArchWidth = 0
    if (countOfFoldGroup <= 1) {
      upMaxArchWidth = upStageCount * (singleStageWidth + StageDiv)
      downMaxArchWidth = downStageCount * (singleStageWidth + StageDiv)
    } else {
      upMaxArchWidth = upStageCount * (singleStageWidth+1)
      downMaxArchWidth = downStageCount * (singleStageWidth+1)
    }

    const biteJumpWidth =
      isDownArchBiteMoved() && ((upStageCount > 0) || (downStageCount > 0)) ? 60 : 0

    upMaxArchWidth = upMaxArchWidth
    downMaxArchWidth = downMaxArchWidth + biteJumpWidth

    return Math.max(upMaxArchWidth, downMaxArchWidth)
  }

  const calcStageCount = (upStateCount, downStageCount) => {
    const maxCountOfFoldGroup = 20
    const minActualSingleStageWidth = 5

    let tmpCountOfFoldGroup = 1
    let tmpActualSingleStageWidth = singleStageWidth
    for(let i=0; i<1000; i++)
    {
      const maxArchWidth = calcMaxArchWidth(tmpCountOfFoldGroup, upStateCount, downStageCount, tmpActualSingleStageWidth)
      if (maxArchWidth <= stageMaxWidthLatest.current-40) {
        break;
      }

      if(tmpCountOfFoldGroup === 1) {
        tmpCountOfFoldGroup = 5
      }
      else if(tmpCountOfFoldGroup > 1) {
        //single stage, max width 23
        if(tmpCountOfFoldGroup === 5) {
          if(tmpActualSingleStageWidth <= singleStageWidth-6) {
            tmpCountOfFoldGroup = tmpCountOfFoldGroup * 2
            tmpActualSingleStageWidth = singleStageWidth
          }
          else {
            tmpActualSingleStageWidth --
          }
        }
        else if(tmpCountOfFoldGroup === 10) {
          if(tmpActualSingleStageWidth <= singleStageWidth-13) {
            tmpCountOfFoldGroup = tmpCountOfFoldGroup * 2
            tmpActualSingleStageWidth = singleStageWidth
          }
          else {
            tmpActualSingleStageWidth --
          }
        }
        else if(tmpCountOfFoldGroup === 20) {
          if(tmpActualSingleStageWidth <= minActualSingleStageWidth) {
            tmpCountOfFoldGroup = tmpCountOfFoldGroup * 2
            tmpActualSingleStageWidth = singleStageWidth
          }
          else {
            tmpActualSingleStageWidth --
          }
        }
        
        if(tmpCountOfFoldGroup >= maxCountOfFoldGroup && tmpActualSingleStageWidth <= minActualSingleStageWidth) {
          break;
        }
      }
    }

    setCountOfFoldGroup(tmpCountOfFoldGroup)
    setActualSingleStageWidth(tmpActualSingleStageWidth)
  }

  useEffect(() => {
    // const maxStateCount = Math.max(
    //   upNodes.length,
    //   downNodes.length,
    // )
    calcStageCount(upNodes.length, downNodes.length)
  }, [stageMaxWidth])

  const GetNodeTypes = (nodeType) => {
    const eNodeTypes: number[] = [];
    for (let index = 0; index < 16; index++) {
      const val = 1 << index;
      if (nodeType & val) {
        eNodeTypes.push(index);
      }
    }
    return eNodeTypes;
  };

  const getNodes = (viewIndex, archType) => {
    const historyInfoArray = treatView.GetHistoryDataInfo(viewIndex, archType)
    const nodes = treatView.GetNodes(viewIndex, archType)
    let nodeStuArray: NodeStru[] = [];
    if(nodes) {
      const nodesSize = nodes.size();
      let lastStepNumber = 0;
      if (nodesSize > 0) {
        const lastNode = nodes.get(nodesSize - 1);
        lastStepNumber = lastNode.stepindex;
        // console.info("=====lastStepNumber:" + lastStepNumber)
      }
  
      for (let i = 0; i <= lastStepNumber && lastStepNumber > 0; i++) {
        const nodeStru: NodeStru = {
          nodeType: [],
          stepindex: -1,
          posPace: 0.25,
          degreePace: 2.5,
          name: "",
        };
        nodeStru.stepindex = i;
        nodeStru.name = i + "";
        if(i < historyInfoArray.length) {
          nodeStru.name = historyInfoArray[i];
        }
  
        for (let j = 0; j < nodes.size(); j++) {
          const node = nodes.get(j);
          if (node.stepindex === i) {
            // console.log('setnode index::',i)
            nodeStru.degreePace = node.degreePace;
            nodeStru.posPace = node.posPace;
            nodeStru.nodeType = GetNodeTypes(node.GetNodeType());
          }
        }
        nodeStuArray.push(nodeStru);
      }
    }

    return nodeStuArray;
  }

  const loadData = () => {
    //start 

    //set nodes
    let upNodeStuArray = getNodes(viewIndex, 0)
    setUpNodes(upNodeStuArray)

    let downNodeStuArray = getNodes(viewIndex, 1)
    setDownNodes(downNodeStuArray)

    //judge the up quick3
    let upQuick3Index = -1
    upNodeStuArray.map((item, index) => {
      if(isNodeContains(item.nodeType, 6)) {
        upQuick3Index = index
      }
    })
    setUpQuick3Index(upQuick3Index)
    //judge the down quick3
    let downQuick3Index = -1
    downNodeStuArray.map((item, index) => {
      if(isNodeContains(item.nodeType, 6)) {
        downQuick3Index = index
      }
    })
    setDownQuick3Index(downQuick3Index)

    // const maxStateCount = Math.max(
    //   upNodeStuArray.length,
    //   downNodeStuArray.length,
    // )
    calcStageCount(upNodeStuArray.length, downNodeStuArray.length)

    up_current = upNodeStuArray.length - 1
    if(up_current < 0) {
      up_current = 0
    }
    down_current = downNodeStuArray.length - 1
    if(down_current < 0) {
      down_current = 0
    }
    setUpStageCurrentStep(up_current)
    setDownStageCurrentStep(down_current)
    setDragBarStep(Math.max(up_current, down_current))
    execSetStepAsync()

    setIsGmClicked(true)
    setGmPercent(1.0)
    treatView.SetBiteJumType(viewIndex, EBiteJumpLocationType.EBiteJumpLocation_End)
    treatView.UpdateLowerArchActorAfterSetGMType(viewIndex)
  }

  const isDownArchBiteMoved = () => {
    const hasGM = treatView.IsHaveGM(viewIndex)
    return hasGM
  }

  const gmFrontButtonClickHandle = () => {
    if (!isGmClicked) {
      setIsGmClicked(true)
      // stageControl.setBiteJumpTypePlayGM(
      //   EBiteJumpLocationType["EBiteJumpLocation_Start"],
      // )
    } else {
      setIsGmClicked(false)
      setGmPercent(0.0)
      // stageControl.setBiteJumpTypePlayGM(
      //   EBiteJumpLocationType["EBiteJumpLocation_All"],
      // )
    }
  }

  const gmBackButtonClickHandle = () => {
    // setIsGmClicked(false)
    // treatView.PlayGM(viewIndex)
    if (!isGmClicked) {
      //wasmModule.wrapInstance.ShowCollision(false)
      setIsGmClicked(true)
      treatView.SetBiteJumType(viewIndex, EBiteJumpLocationType.EBiteJumpLocation_End)
      treatView.PlayGM(viewIndex)
      treatView.UpdateLowerArchActorAfterSetGMType(viewIndex)
    } else {
      setIsGmClicked(false)
      treatView.SetBiteJumType(viewIndex, EBiteJumpLocationType.EBiteJumpLocation_Off)
      treatView.UpdateLowerArchActorAfterSetGMType(viewIndex)
      setGmPercent(0.0)
    }
  }

  const updateGM0Progress = (viewID, percent) => {
    if(viewIndex === 0 && viewID === 0) {
      console.info("updateTreatGMProgress: ", viewID, ",percent:", percent)
      setGmPercent(percent)
      if(percent >= 0.99999) {
        setIsGmClicked(true)
       // wasmModule.wrapInstance.ShowCollision(true)
      }
    }
  }

  const updateGM1Progress = (viewID, percent) => {
    if(viewIndex === 1 && viewID === 1) {
      console.info("updateTreatGMProgress: ", viewID, ",percent:", percent)
      setGmPercent(percent)
      if(percent >= 0.99999) {
        setIsGmClicked(true)
        //wasmModule.wrapInstance.ShowCollision(true)
      }
    }
  }

  useEffect(() => {
    // updateAll()
    // if (onOff) {
    if(preparation) {
      loadData()
    }
    // }
  }, [onOff])

  useEffect(() => {
    // updateAll()
    // loadData()
    if(viewIndex === 0) {
      stagingManager.setGM0ProgressCallBack = updateGM0Progress
    }
    else if(viewIndex === 1) {
      stagingManager.setGM1ProgressCallBack = updateGM1Progress
    }
    return () => {
      console.log('dealloc stageBarForCompare');
      // dispatch(setStageShowType(""))
      // stagingManager.clearWasmStageData()
    };
  }, [])
  //useEffect(() => {
  //setStageStep(up_current, down_current)
  //}, [up_current, down_current])
  useEffect(() => {
    clearInterval(timer)
    runStep_up = 0
    runStep_down = 0
    if (btnObj.type === "icStart") {
      //setUpCurrent(0)
      //setDownCurrent(0)
      up_current = 0
      down_current = 0
      setUpStageCurrentStep(up_current)
      setDownStageCurrentStep(down_current)
      setDragBarStep(Math.max(up_current, down_current))
      execSetStepAsync()
    } else if (btnObj.type === "icEnd") {
      if (upNodes.length) {
        //setUpCurrent(upList.length - 1)
        up_current = upNodes.length - 1
      }
      if (downNodes.length) {
        //setDownCurrent(downList.length - 1)
        down_current = downNodes.length - 1
      }
      setUpStageCurrentStep(up_current)
      setDownStageCurrentStep(down_current)
      setDragBarStep(Math.max(up_current, down_current))
      execSetStepAsync()
    } else {
      playOrStop()
    }
  }, [btnObj])
  useEffect(() => {
    const upSlider = document.getElementById("sliderUp")
    const downSlider = document.getElementById("sliderDown")
  }, [isFold, unitLen])
  // useEffect(() => {
  //   setStageObj({ upList, downList, up_current, down_current })
  // }, [upList, downList, up_current, down_current])
  useEffect(() => {
    if (preparation) {
      up_current = upNodes.length ? upNodes.length - 1 : 0
      down_current = downNodes.length ? downNodes.length - 1 : 0
      //setStageStep(up_current, down_current)
      //setUpCurrent(upList.length ? upList.length - 1 : 0)
      //setDownCurrent(downList.length ? downList.length - 1 : 0)
    }
  }, [preparation])
  useEffect(() => {
    let { width = 0 } = resizeObserverEntry.contentRect || {}
    width = Math.max(width + 80, stageMinWidth)
    width = Math.min(width, stageMaxWidthLatest.current)
    if (width !== boxWidth) {
      setBoxWidth(width)
      setStageBarWidth(width)
      setDragBarStep(Math.max(up_current, down_current))
    }
    const { height = 0 } = resizeObserverEntry.contentRect || {}
    if (height !== boxHeight) {
      setStagebarHeight(height)
    }
  })

  return (
    <Paper
      className={"stageBarBackground"}
      sx={{
        minWidth: stageBarWidth,
        height: "116px",
        display: "flex",
        justifyContent: "center",
        background: "#FFFFFFCC",
        borderTopLeftRadius: showTxTab ? "0px" : "6px",
        borderTopRightRadius: "6px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
      }}
    >
      <Box
        className={"stageBar"}
        ref={resizeObserverRef}
      >
        <div
          className="refineStageContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            // flexDirection:
            // !upList.length || !downList.length ? "unset" : "column",
            position: "relative",
          }}
        >
          <div
            className={"stageContainer"}
            style={{
              // display: upList.length ? "flex" : "none",
              //marginTop: isRefinement ? "20px" : "50px",
              //
              //marginBottom: isRefinement ? "20px" : "unset"
              display: "flex",
              marginTop: "4px",
              zIndex: 0,
            }}
          >
            <div
              className="upStageTitleContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                marginTop: "12px",
              }}
            >
              {/* <Box sx={{width: "1px", height: "20px"}}/> */}
              <div
                className={"directName"}
                style={{
                  width: "40px",
                  color: "#000000",
                  fontSize: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                }}
              >
                U
              </div>
            </div>

            <StageArch
              onRef={stageUpArchRef}
              stageCount={upSteps}
              nodes={upNodes}
              archName="Up"
              archType={0}
              stepChangeHandler={stepChangeHandler}
              currentStep={upStageCurrentStep}
              loadFinished={stageArchLoadFinished}
              countOfFoldGroup={countOfFoldGroup}
              setDefaultStep={updateDefaultStepValue}
              uiShowType={""}
              actualSingleStageWidth={actualSingleStageWidth}
              viewEdit={false}
              isDownArchBiteMoved={isDownArchBiteMoved}
              gmPercent={gmPercent}
              setGmPercent={setGmPercent}
              isGmClicked={isGmClicked}
              setIsGmClicked={setIsGmClicked}
              gmFrontButtonClickHandle={gmFrontButtonClickHandle}
              gmBackButtonClickHandle={gmBackButtonClickHandle}
              upQuick3Index={upQuick3Index}
              downQuick3Index={downQuick3Index}
            />
          </div>

          <div
            className={"stageContainer"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "7px",
              width: "50px",
              zIndex: 0,
              // background: "gray",
            }}
          >
            <StageDragBar
              onRef={stageDragBarRef}
              visibility={
                upNodes.length > 0 || downNodes.length > 0 ? "" : "hidden"
              }
              positionXChanged={dragBarPositionXChanged}
              dragStoped={dragBarStoped}
              width={50}
              height={6}
              top={-6}
              x={dragBarLeft - 25 - (countOfFoldGroup === 1 ? 1.5  : 0)}
              bounds={{ left: -30000, right: 30000, top: 0, bottom: 0 }}
            />

            {/* <Box sx={{width: "1px", height: "1px"}}></Box> */}

          </div>

          <div
            className={"stageContainer"}
            style={{
              // display: downList.length ? "flex" : "none",
              display: "flex",
              marginTop: "unset", //!isRefinement ? upList.length ? "unset" : "70px" : "unset",
            }}
          >
            <div
              className="downStageTitleContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                marginBottom: "5px",
              }}
            >
              {/* <Box sx={{width: "1px", height: "20px"}}/> */}
              <div
                className={"directName"}
                style={{
                  width: "40px",
                  color: "#000000",
                  fontSize: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                }}
              >
                L
              </div>
            </div>

            <StageArch
              className={"stageSlider"}
              onRef={stageDownArchRef}
              stageCount={downSteps}
              nodes={downNodes}
              archName="Down"
              archType={1}
              stepChangeHandler={stepChangeHandler}
              currentStep={downStageCurrentStep}
              loadFinished={stageArchLoadFinished}
              countOfFoldGroup={countOfFoldGroup}
              setDefaultStep={updateDefaultStepValue}
              uiShowType={""}
              actualSingleStageWidth={actualSingleStageWidth}
              viewEdit={false}
              isDownArchBiteMoved={isDownArchBiteMoved}
              gmPercent={gmPercent}
              setGmPercent={setGmPercent}
              isGmClicked={isGmClicked}
              setIsGmClicked={setIsGmClicked}
              gmFrontButtonClickHandle={gmFrontButtonClickHandle}
              gmBackButtonClickHandle={gmBackButtonClickHandle}
              upQuick3Index={upQuick3Index}
              downQuick3Index={downQuick3Index}
            />
          </div>
        </div>
      </Box>
    </Paper>
  )
}
export default StageBarForCompare
