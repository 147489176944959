import * as React from "react"
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import { gdsPlusSetup, MidlineType, OJOBType } from "@/gluelayer"
import { stagingManager } from "@/gluelayer"
import { EBiteJumpLocationType, stageControl } from "@/gluelayer"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateAutoSetup, setParamValue } from "@/UDTreat/udSetupSlice"
import { FlowBtnGroup, FlowNameSliderGrid } from "../../custom/FlowCustom"
import { setUpdateSetup, setUpdateStaging } from "../../udTreatSlice"
import { FlowSliders } from "../../custom/FlowCustom"
import { UDSetupStatus } from "@/UDTreat/UDesign/UDSetupStatus"

export const WeSetupPosterior = (props) => {
  const { Params, TMJMoveDist, disabled } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const dispatch = useAppDispatch()

  const apBtns = [
    { label: "Auto AP", name: "AutoAP" },
    { label: "No AP", name: "NoAP" },
  ]

  const occBtns = [
    { label: "Full Arch", name: "OccFull" },
    { label: "Post Only", name: "OccPost" },
    { label: "Sel. Teeth", name: "OccTeeth" },
  ]

  const gmBtns = [
    { label: "Auto", name: "GMAuto" },
    { label: "Remove", name: "GMRemove" },
    { label: "Min Upper", name: "GMMin" },
  ]

  const maxGmSliders = [
    { ...Params.TMJMoveLimit, step: 0.1, min: 0, max: 3, delta: [0.1, 0.5] },
  ]

  React.useEffect(() => {
    console.log("mount anterior")
    dispatch(updateAutoSetup())
    return () => {
      console.log("unmount anterior")
    }
  }, [])

  const onSliderChange = (name: string, value: number) => {
    // stagingManager.setEndNodeIndex()
    const payload = { name, value }
    dispatch(setParamValue(payload))
  }

  const onSliderChangeCommitted = (name: string, value: number) => {
    stagingManager.setEndNodeIndex()
    gdsPlusSetup.SetParamValue(name, value)
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }

  const onButtonClick = (btnName: string) => {
    stagingManager.setEndNodeIndex()
    console.log(btnName, "clicked")
    switch (btnName) {
      case "AutoAP":
        gdsPlusSetup.SetupAPUpperToLower()
        break
      case "NoAP":
        gdsPlusSetup.NoAPControl()
        break
      case "OccFull":
        gdsPlusSetup.ResolveOccColl(false, false, () => ({}))
        break
      case "OccPost":
        gdsPlusSetup.ResolveOccColl(true, false, () => ({}))
        break
      case "OccTeeth":
        gdsPlusSetup.ResolveOccColl(true, true, () => ({}))
        break
      case "GMAuto":
        gdsPlusSetup.SetLowerGlobalMovement(true, false)
        break
      case "GMRemove":
        gdsPlusSetup.SetLowerGlobalMovement(false, false)
        break
      case "GMMin":
        gdsPlusSetup.SetLowerGlobalMovement(true, true)
        break
    }
    dispatch(updateAutoSetup())
    dispatch(setUpdateSetup())
    dispatch(setUpdateStaging())
  }
  const DrawTMJMoveDist = () => {
    return (
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={3}>
          <Typography variant="body2">TMJ Dist</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Left</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="body2">
            {TMJMoveDist.leftDist.toFixed(2) + " mm"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Right</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="body2">
            {TMJMoveDist.rightDist.toFixed(2) + " mm"}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const BiteJump = () => {
    const onClickBtn = (btnName: string) => {
      console.log("Bite jump button", btnName)
      const jumpType =
        btnName == "Off"
          ? EBiteJumpLocationType.EBiteJumpLocation_Off
          : btnName == "All"
          ? EBiteJumpLocationType.EBiteJumpLocation_All
          : btnName == "Start"
          ? EBiteJumpLocationType.EBiteJumpLocation_Start
          : btnName == "End"
          ? EBiteJumpLocationType.EBiteJumpLocation_End
          : EBiteJumpLocationType.EBiteJumpLocation_All
      stageControl.setBiteJumpType(jumpType)
      setUpdateSetup()
      setUpdateStaging()
    }
    return (
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              onClickBtn("Off")
            }}
          >
            Off
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              onClickBtn("All")
            }}
          >
            All
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              onClickBtn("Start")
            }}
          >
            Start
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              onClickBtn("End")
            }}
          >
            End
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Stack direction="column" spacing={1}>
      <UDSetupStatus />
      <Typography variant="body1">AP Control</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={apBtns}
        onButtonClick={onButtonClick}
      />
      <Divider />
      <Typography variant="body1">Occlusal Contact</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={occBtns}
        onButtonClick={onButtonClick}
      />
      <Divider />
      <Typography variant="body1">Global Movement</Typography>
      <FlowBtnGroup
        disabled={disabled}
        btnGroup={gmBtns}
        onButtonClick={onButtonClick}
      />
      <FlowSliders
        params={maxGmSliders}
        onChangeFunc={onSliderChange}
        onChangeCommittedFunc={onSliderChangeCommitted}
        disabled={disabled}
      />
      <DrawTMJMoveDist />
      <Typography variant="body1">Bite Jump</Typography>
      <BiteJump />
    </Stack>
  )
}
