import { Paper, Box, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import { lightGreen } from "@mui/material/colors"
import { useTheme } from "@mui/material/styles"
import { Backdrop, LinearProgress } from "@mui/material"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

export const RightPage = (props: any) => {
  const { openProgressDlg, progressDlgMsg } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const theme = useTheme()
  return (
    <>
      <Stack
        sx={{
          ...props.sx,
          right: 72,
          top: 104,
          minWidth: "300px",
          maxWidth: "536px",
          borderRadius: "8px",
          backgroundColor: theme.transBkColor.light,
          maxHeight: `calc(100% - 114px)`,
          position: "absolute",
          boxShadow: 6,
        }}
        direction="column"
      >
        <Stack
          sx={{
            p: "24px",
            height: "48px",
            width: "100%",
          }}
          alignItems="center"
          justifyContent="space-between"
          direction={"row"}
        >
          <Typography variant="h6"> {props.title}</Typography>
          <IconButton
            sx={{
              width: 30,
              height: 30,
            }}
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            p: "24px",
            backgroundColor: "background.paper",
            overflow: "auto",
          }}
        >
          {props.content}
        </Box>
        {props.actions ? (
          <Box sx={{ width: "100%" }}> {props.actions}</Box>
        ) : (
          <Box sx={{ width: "100%", height: "20px" }}></Box>
        )}
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openProgressDlg}
      >
        <Box sx={{ width: "40%" }}>
          <div id="ProgressMsg"> </div>
          {progressDlgMsg}
          <LinearProgress />
        </Box>
      </Backdrop>
    </>
  )
}
