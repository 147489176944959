import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Button, Select, Stack, Grid } from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { getCaseById } from "@/core/app/slices/case"
import { updateCaseStatus, updateCaseUnarchive } from "@/core/app/slices/case"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import { UDTitleSelect } from "@/UDTreat/custom/UDSelectOptions"
import { navigateTo } from "@/modules/Patient/utils"
import { updateCurrentOrgId } from "@/core/app/slices/user/userSlice"
import { PatientNameID } from "@/UDTreat/top/PatientNameID"
import { useMyNavigation } from "@/hooks/useMyNavigation"

export const CASE_STATUS1 = {
  COMPLETE_PATIENT_RECORDS: "COMPLETE_PATIENT_RECORDS",
  FILL_OUT_RX_FORM: "FILL_OUT_RX_FORM",
  SUBMIT_TO_UASSIST: "SUBMIT_TO_UASSIST",
  SUBMITTED_TO_UASSIST: "SUBMITTED_TO_UASSIST",
  PLANNING_IN_PROGRESS: "PLANNING_IN_PROGRESS",
  REVIEW_PLAN: "REVIEW_PLAN",
  MODIFICATION_REQUESTED: "MODIFICATION_REQUESTED",
  REVIEW_MODIFICATION: "REVIEW_MODIFICATION",
  PLACE_ORDER: "PLACE_ORDER",
  ATTENTION_NEEDED: "ATTENTION_NEEDED",
  IN_PRODUCTION: "IN_PRODUCTION",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  ORDER_SUBMITTED: "ORDER_SUBMITTED",
  TREATMENT_IN_PROGRESS: "TREATMENT_IN_PROGRESS",
  TREATMENT_COMPLETE: "TREATMENT_COMPLETE",
  ARCHIVED: "ARCHIVED",
  CANCELLED: "CANCELLED",
  SUBMITTED_TO_UASSIST_REFINEMENT: "SUBMITTED_TO_UASSIST_REFINEMENT",
  PLANNING_IN_PROGRESS_REFINEMENT: "PLANNING_IN_PROGRESS_REFINEMENT",
  ATTENTION_NEEDED_REFINEMENT: "ATTENTION_NEEDED_REFINEMENT",
  FROM_UDESIGN: "FROM_UDESIGN",
  REJECTED: "REJECTED",

  DEFAULT: "",
}

export const WeCaseStatus = () => {
  const { patientId, caseId } = useParams()
  const [updateStatus1, setUpdateStatus1] = useState("")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { user_id, isCustomerSupport, my_orgId, current_orgId } =
    useAppSelector((state: RootState) => state.userService.user)
  let curStatus = ""

  const changeStatus = async (newStatus: string) => {
    await dispatch(
      updateCaseStatus({
        patientId: patientId.toString(),
        caseId: caseId,
        payload: {
          case_disposition: newStatus,
        },
      }),
    )
  }

  const onSubmitCase = () => {
    changeStatus(CASE_STATUS.FILL_OUT_RX_FORM).then(() =>
      changeStatus(CASE_STATUS.SUBMIT_TO_UASSIST).then(() =>
        changeStatus(CASE_STATUS.SUBMITTED_TO_UASSIST).then(() =>
          dispatch(getCaseById({ patientId: patientId, caseId: caseId })),
        ),
      ),
    )
  }
  const onModiCase = () => {
    changeStatus(CASE_STATUS.PLANNING_IN_PROGRESS).then(() =>
      changeStatus(CASE_STATUS.REVIEW_PLAN).then(() =>
        dispatch(getCaseById({ patientId: patientId, caseId: caseId })),
      ),
    )
  }
  const onFinishCase = () => {
    changeStatus(CASE_STATUS.FILL_OUT_RX_FORM).then(() =>
      changeStatus(CASE_STATUS.SUBMIT_TO_UASSIST).then(() =>
        changeStatus(CASE_STATUS.SUBMITTED_TO_UASSIST).then(() =>
          dispatch(getCaseById({ patientId: patientId, caseId: caseId })),
        ),
      ),
    )
  }

  const onSubmitCasex = () => {
    dispatch(
      updateCaseStatus({
        patientId: patientId.toString(),
        caseId: caseId,
        payload: {
          case_disposition: CASE_STATUS.FILL_OUT_RX_FORM,
        },
      }),
    ).then(() => {
      dispatch(
        updateCaseStatus({
          patientId: patientId.toString(),
          caseId: caseId,
          payload: {
            case_disposition: CASE_STATUS.SUBMIT_TO_UASSIST,
          },
        }),
      ).then(() => {
        updateCaseStatus({
          patientId: patientId.toString(),
          caseId: caseId,
          payload: {
            case_disposition: CASE_STATUS.SUBMITTED_TO_UASSIST,
          },
        })
      })
    })
    dispatch(getCaseById({ patientId: patientId, caseId: caseId }))
  }

  const onClickSetStatus = (newStatus: string) => {
    if (curStatus == newStatus) return
    // const envStr = import.meta.env.VITE_NODE_ENV
    // if ((envStr === '"local"' || envStr === '"qausa"') && isCustomerSupport)
    //   return // CS don't have right to change anything in US server.
    curStatus = newStatus
    setUpdateStatus1("Start update status...")
    dispatch(
      updateCaseStatus({
        patientId: patientId.toString(),
        caseId: caseId,
        payload: {
          case_disposition: newStatus,
        },
      }),
    ).then((res) => {
      if (res && res.payload && res.payload.response_code === 200) {
        setUpdateStatus1("Update status successed... ")
        dispatch(getCaseById({ patientId: patientId, caseId: caseId }))
      } else {
        setUpdateStatus1("Update status failed... ")
      }
    })
  }
  return (
    <Stack direction="column">
      <Box> </Box>
      <Box>
        ID: {caseDetail.create_org_id} {patientId} {caseId}{" "}
      </Box>
      <Box>Assigned to: {caseDetail.assigned_to} </Box>
      <Box>Create date : {caseDetail.createDate} </Box>
      <Box>Case caseDisposition : {caseDetail.caseDisposition} </Box>
      <Box>Status : {caseDetail.status} </Box> {/* */}
      <Box> 1234 {updateStatus1}</Box>
      <Grid container alignItems={"center"}>
        <UDTitleSelect
          title="Set Status"
          options={[
            CASE_STATUS.COMPLETE_PATIENT_RECORDS,
            CASE_STATUS.FILL_OUT_RX_FORM,
            CASE_STATUS.SUBMIT_TO_UASSIST,
            CASE_STATUS.SUBMITTED_TO_UASSIST,
            CASE_STATUS.PLANNING_IN_PROGRESS,
            CASE_STATUS.REJECTED,
            CASE_STATUS.ATTENTION_NEEDED,
            CASE_STATUS.REVIEW_PLAN,
            CASE_STATUS.MODIFICATION_REQUESTED,
            CASE_STATUS.REVIEW_MODIFICATION,
            CASE_STATUS.PLACE_ORDER,
            CASE_STATUS.ORDER_SUBMITTED,
            CASE_STATUS.IN_PRODUCTION,
            CASE_STATUS.SHIPPED,
            CASE_STATUS.DELIVERED,
            CASE_STATUS.FROM_UDESIGN,
            CASE_STATUS.TREATMENT_IN_PROGRESS,
            CASE_STATUS.TREATMENT_COMPLETE,
            CASE_STATUS.ARCHIVED,
          ]}
          onChangeSel={(option) => {
            onClickSetStatus(option)
          }}
        />
      </Grid>
      <Box>
        <Button variant="contained" onClick={onSubmitCase}>
          Submit Case to uAsssit
        </Button>
      </Box>
      <Box>
        <Button variant="contained" onClick={onModiCase}>
          Review Case
        </Button>
      </Box>
      <Box>
        <Button variant="contained" onClick={onModiCase}>
          Archive Case
        </Button>
      </Box>
    </Stack>
  )
}
