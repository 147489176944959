import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { moduleMeshEditV2, wasmModule } from "@/gluelayer"
import { Stack, Button, Typography, Checkbox } from "@mui/material"

export const MeshExplorer = (props) => {
  const [meshInfos, setMeshInfos] = useState([])

  const getMeshInfos = () => {
    const infoStr = moduleMeshEditV2.GetAllMeshInfosStr()
    const meshInfos = JSON.parse(infoStr)
    setMeshInfos(meshInfos)
  }
  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("Open mesh edit module")
    moduleMeshEditV2.SwitchModuleMeshEditV2(true)
    getMeshInfos()
    return () => {
      console.log("End mesh edit module")
      moduleMeshEditV2.SwitchModuleMeshEditV2(false)
    }
  }, [])
  const showAll = (show) => {
    meshInfos.map((info) => {
      moduleMeshEditV2.ShowMesh(info.tid, show)
    })
    getMeshInfos()
  }
  const showMesh = (tid, show) => {
    moduleMeshEditV2.ShowMesh(tid, show)
    getMeshInfos()
  }
  const ShowMeshInfo = ({ tid, show }) => {
    const getMeshName = (tid) => {
      if (tid == -1) return "Upper Gum"
      else if (tid == -2) return "Lower Gum"
      else return "T_" + tid
    }
    return (
      <Stack direction="row">
        <Checkbox
          checked={show}
          onClick={() => {
            showMesh(tid, !show)
          }}
        />
        <Typography variant="body2">{getMeshName(tid)}</Typography>
      </Stack>
    )
  }
  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          onClick={() => {
            showAll(true)
          }}
        >
          Show All
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            showAll(false)
          }}
        >
          Hide All
        </Button>
      </Stack>
      {meshInfos.map((info) => {
        return <ShowMeshInfo tid={info.tid} show={info.show} />
      })}
    </Stack>
  )
}
