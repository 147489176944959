import type { FC } from "react"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Typography,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { fetchFormLists } from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/ui-component/components/index"

import RefinementList from "../../../src/modules/Records/RefinementList"
import { checkIsThereASpecifiedPhoto } from "../left/utils"
import { setCurLeftPage } from "../udTreatSlice"
import FormOk from "../USmartForms/FormOk"
import OrderForms from "../USmartForms/OrderForms"
import Prescription from "../USmartForms/Prescription"
import Retainer from "../USmartForms/Retainer"
import {getFormByName} from "@/core/app/slices/uassist/form.util";
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant";
import {resetPrescriptionSchema} from "@/core/app/slices/uassist/formSlice";

const canEdit = ["SUBMITTED_TO_UASSIST", "FROM_UDESIGN"]
const canEditDontSubmit = ["SUBMITTED_TO_UASSIST"]
// const canEdit = [
//   "SUBMITTED_TO_UASSIST",
//   "FROM_UDESIGN",
//   "SUBMITTED_TO_UASSIST_REFINEMENT",
// ]
const SmartRx: FC = ({
  smartRxShow,
  setSmartRxShow,
  needToSubmit,
  styleObj = {},
  unlockFunc = () => {},
}) => {
  const { photoTypeJson } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { caseDisposition, uassist_progress } = caseDetail
  const { formTemplateList, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const navigate = useMyNavigation()
  const smartRef = useRef("")
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { caseRAtype } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { caseId } = useParams()
  // bottom content type----edit or review or success
  const [actionType, setActionType] = useState<string>("edit")
  // review smartRX signal
  const [reviewNum, setReviewNum] = useState<number>(0)
  const [currentReviewNum, setCurrentReviewNum] = useState<number>(0)
  useEffect(() => {
    if (!smartRxShow) {
      setReviewNum(0)
      if (needToSubmit) {
        if (uassist_progress === "READY") {
          setActionType("edit")
        }
      } else {
        if (canEditDontSubmit.includes(caseDisposition)) {
          setActionType("edit")
        }
      }
    }
  }, [smartRxShow])

  const [isRetainer, setIsRetainer] = useState<boolean>(false)

  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.udesign_category &&
      !caseDetail?.udesign_category?.includes("A")
    ) {
      setIsRetainer(true)
    }
  }, [caseDetail?.udesign_category])
  useEffect(() => {
    if (actionType !== "success") {
      if (needToSubmit) {
        setActionType(uassist_progress === "READY" ? "edit" : "review")
      } else {
        setActionType(
          canEditDontSubmit.includes(caseDisposition) ? "edit" : "review",
        )
      }
    }
  }, [caseDisposition])
  useEffect(() => {
    return () => {
      dispatch(setSmartRxShow(false))
    }
  }, [])
  const [photoPopup, setPhotoPopup] = useState<boolean>(false)
  return actionType === "success" ? (
    <FormOk actionType={actionType} />
  ) : (
    smartRef && smartRxShow && (
      <Paper
        sx={{
          top: 104,
          width: "auto",
          maxWidth: "640px",
          minWidth: "536px",
          height: `calc(100% - 114px)`,
          position: "absolute",
          borderRadius: "8px",
          overflow:"hidden",
          zIndex: 1,
          ...styleObj,
        }}
        elevation={6}
      >
        <Card
          sx={{
            height: "100%",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CardHeader
            sx={{
              padding: "24px 32px 16px 32px",
              backgroundColor: "#f1f1f2",
              borderRadius: "8px",
              "& .MuiTypography-root": {
                fontSize: "24px",
                fontWeight: 500,
              },
            }}
            action={
              actionType !== "success" ? (
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    dispatch(setSmartRxShow(false))
                    if (!needToSubmit) {
                      dispatch(setCurLeftPage(""))
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <></>
              )
            }
            title={
              <div>
                <Typography>
                  {actionType === "edit"
                    ? "uAssist Prescription"
                    : actionType === "review"
                    ? "Prescription"
                    : actionType === "smartRX"
                    ? ""
                    : "Thank you"}
                </Typography>
                <Typography sx={{}}>
                  {!needToSubmit && (
                    <RefinementList
                      isShow={true}
                      listSX={{ marginTop: "10px" }}
                      caseId={caseId}
                      onRefinementChange={(reviewNum) => {
                        dispatch(resetPrescriptionSchema())
                        setCurrentReviewNum(reviewNum)
                        }
                      }
                    />
                  )}
                </Typography>
              </div>
            }
          />
          <CardContent
            sx={{
              padding: "24px 32px",
              // height: actionType === "success" ? "288px" : "500px",
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            {
              <Box
                sx={{
                  height: "fit-content",
                }}
              >
                {/* <WarnBox /> */}
                {/* {actionType === "review" && (
                  <Box
                    sx={{
                      width: "100%",
                      padding: "8px",
                      height: "40px",
                      marginBottom: "8px",
                      backgroundColor: "#eee",
                      fontSize: "12px",
                      color: "rgba(0,0,0,0.6)",
                      lineHeight: "24px",
                    }}
                  >
                    {"REVIW PRESCRIPTION"}
                  </Box>
                )} */}
                {currentReviewNum === 0 || rxPrescription?.id == getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.PRESCRIPTION_2).id ? (
                  isRetainer ? (
                    <Retainer
                      needToSubmit={needToSubmit}
                      setUpperArchType={setUpperArchType}
                      setLowerArchType={setLowerArchType}
                      reviewNum={reviewNum}
                      setActionType={setActionType}
                      actionType={
                        actionType === "edit" &&
                        (currentReviewNum == (caseDetail.uassist_round || 0) || needToSubmit)
                          ? "edit"
                          : "review"
                      }
                    />
                  ) : (
                    <Prescription
                      needToSubmit={needToSubmit}
                      setUpperArchType={setUpperArchType}
                      setLowerArchType={setLowerArchType}
                      reviewNum={reviewNum}
                      setActionType={setActionType}
                      actionType={
                        actionType === "edit" &&
                        (currentReviewNum == (caseDetail.uassist_round || 0) || needToSubmit)
                          ? "edit"
                          : "review"
                      }
                      unlockFunc={unlockFunc}
                    />
                  )
                ) : (
                  // <OrderForms />
                  <OrderForms
                    needToSubmit={needToSubmit}
                    reviewNum={reviewNum}
                    setActionType={setActionType}
                    actionType={
                      actionType === "edit" &&
                      currentReviewNum == (caseDetail.uassist_round || 0)
                        ? "edit"
                        : "review"
                    }
                    currentReviewNum={currentReviewNum}
                    isAdd={false}
                  />
                )}
              </Box>
            }
          </CardContent>
          <CardActions
            sx={{
              width: "100%",
              padding: "16px 32px 24px 24px",
              backgroundColor: "#EDF6F9",
              display: needToSubmit
                ? uassist_progress === "READY"
                  ? "flex"
                  : "none"
                : canEditDontSubmit.includes(caseDisposition)
                ? "flex"
                : "none",
              justifyContent: "end",
            }}
          >
            {actionType === "edit" &&
            (currentReviewNum == (caseDetail.uassist_round || 0) || needToSubmit) ? (
              <Button
                sx={{
                  color: "#fff",
                  padding: "8px 14px 8px 22px",
                  borderRadius: "4px",
                  backgroundColor: "#215ECD",
                }}
                variant="contained"
                onClick={() => {
                  // 提交uassit的表单要判断照片是否具备条件，不具备需要弹窗
                  if (needToSubmit) {
                    const { normalPosition, xrayPosition } =
                      checkIsThereASpecifiedPhoto(photoTypeJson)
                    if (isRetainer || (normalPosition && xrayPosition)) {
                      setReviewNum(reviewNum + 1)
                    } else {
                      setPhotoPopup(true)
                    }
                  } else {
                    setReviewNum(reviewNum + 1)
                  }
                }}
              >
                <span style={{ fontSize: "15px", fontWeight: "500" }}>
                  {"review"}
                </span>
                <ChevronRightIcon
                  sx={{ marginLeft: "8px", width: "24px", height: "24px" }}
                />
              </Button>
            ) : actionType === "review" ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    padding: "8px 11px 8px 8px",
                    borderRadius: "4px",
                    color: "#215ECD",
                  }}
                  variant="text"
                  onClick={() => {
                    setActionType("edit")
                    setReviewNum(0)
                  }}
                >
                  <ChevronLeftIcon
                    sx={{ marginRight: "8px", width: "24px", height: "24px" }}
                  />
                  <span style={{ fontSize: "15px", fontWeight: "500" }}>
                    {"edit"}
                  </span>
                </Button>
                <Box>
                  {/* <Button
                    sx={{
                      padding: "8px 11px 8px 11px",
                      borderRadius: "4px",
                      color: "#215ECD",
                      marginRight: "10px",
                    }}
                    variant="text"
                    onClick={() => {
                      console.log("view ai setup")
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"view ai setup"}
                    </span>
                  </Button> */}
                  {/* {!needToSubmit && <Button
                    variant="contained"
                    onClick={() => {
                      setSubmitToUassist(true)
                    }}
                  >
                      {"Submit to Uassist"}
                  </Button>} */}

                  {needToSubmit && (
                    <Button
                      sx={{
                        color: "#fff",
                        padding: "8px 14px 8px 22px",
                        borderRadius: "4px",
                        backgroundColor: "#215ECD",
                      }}
                      variant="contained"
                      onClick={() => {
                        const { normalPosition, xrayPosition } =
                          checkIsThereASpecifiedPhoto(photoTypeJson)
                        if (isRetainer || (normalPosition && xrayPosition)) {
                          setReviewNum(reviewNum + 1)
                        } else {
                          setPhotoPopup(true)
                        }
                      }}
                    >
                      <span style={{ fontSize: "15px", fontWeight: "500" }}>
                        {"submit"}
                      </span>
                      <ChevronRightIcon
                        sx={{
                          marginLeft: "8px",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
            ) : actionType === "edit" &&
              currentReviewNum != caseDetail.uassist_round ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box></Box>
              </Box>
            ) : actionType === "smartRX" ? (
              <></>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box>
                  <Button
                    sx={{
                      color: "#fff",
                      padding: "8px 14px 8px 22px",
                      borderRadius: "4px",
                      backgroundColor: "#215ECD",
                    }}
                    variant="contained"
                    onClick={() => {
                      navigate("/patients")
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"go to patient list"}
                    </span>
                  </Button>
                </Box>
              </Box>
            )}
          </CardActions>
        </Card>
        <DialogBox
          open={photoPopup}
          title={"Photos and X-Rays Required"}
          content={
            "Please upload photos and x-rays before submitting to uAssist using the panel on the left."
          }
          actions={
            <UButton
              onClickHandler={() => {
                setPhotoPopup(false)
              }}
              variant={"contained"}
              btnText={t("button.ok")}
            />
          }
        />
      </Paper>
    )
  )
}
export default SmartRx
