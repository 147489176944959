import { useEffect } from "react"
import { gdsPlusSetup, wasmModule, SetupType } from "@/gluelayer"
import { UDTabs } from "../../custom/UDTabs"
import { WeSetupType } from "./WeSetupType"
import { WeSetupArchform } from "./WeSetupArchform"
import { WeSetupAnterior } from "./WeSetupAnterior"
import { WeSetupPosterior } from "./WeSetupPosterior"
import { WeSetupIprSpace } from "./WeSetupIprSpace"
import { RightPage } from "@/UDTreat/right/RightPage"
import { useDispatch } from "react-redux"
import { WeSetupCommonActions } from "./WeSetupCommonActions"
import { setDisable, updateAutoSetup } from "@/UDTreat/udSetupSlice"
import { setUpdateStaging } from "@/UDTreat/udTreatSlice"
import { WeRetouch } from "./WeRetouch"

export const WeSetup = (props) => {
  const dispatch = useDispatch()
  const UpdateProgress = (msg: string, percent: number) => {
    console.info("WeSetup, update progress" + msg + percent)
  }
  useEffect(() => {
    if (!wasmModule.isInit) return
    gdsPlusSetup.SwitchAutoSetupModule(true)
    gdsPlusSetup.UpdateProgressCallback = UpdateProgress
    setTimeout(() => {
      gdsPlusSetup.setupByType(SetupType.FineTune, () => {
        console.log("Auto setup the case ...")
        dispatch(setDisable(false))
        dispatch(updateAutoSetup())
        dispatch(setUpdateStaging())
      })
    }, 100)
    return () => {
      gdsPlusSetup.SwitchAutoSetupModule(false)
      console.log("End chairside setup")
      dispatch(setDisable(true))
    }
  }, [])
  return (
    <RightPage
      title="weDesign Setup"
      onClose={props.onClose}
      content={
        <UDTabs
          labels={[
            "Setup Type",
            "IPR/Space",
            "Archform",
            "Anterior",
            "Posterior",
            "RetouchV2",
          ]}
        >
          <WeSetupType />
          <WeSetupIprSpace />
          <WeSetupArchform />
          <WeSetupAnterior />
          <WeSetupPosterior />
          <WeRetouch />
        </UDTabs>
      }
      actions={<WeSetupCommonActions />}
    />
  )
}
