import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import { UText } from "../../../components"
import { Box, Button, Grid } from "../../../components/mui.components"
import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import {
  updaterxFormData,
} from "../../../core/app/slices/uassist/formSlice"
import {
  deleteFormSavedData,
  fetchFormByCaseIdWithMergeData,
  saveDefaultFormData,
  updateFormSavedData,
} from "../../../core/app/slices/uassist/formThunkApi"
import { RootState } from "../../../core/app/store"
import { checkIfObjectHasKeys } from "../../../core/utils/formatters"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import JsonFormBuilder from "../../../rx-form/builder/JsonFormBuilder"

const SmartRxForm: FC<{
  isSettings?: boolean
  isResetData?: boolean
  setIsResetData?: (val: boolean) => void
  isSaveData?: boolean
  setIsSaveData?: (val: boolean) => void
}> = ({
  isSettings,
  isResetData,
  setIsResetData,
  isSaveData,
  setIsSaveData,
}) => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { rxJsonForm } = useAppSelector((state: RootState) => state.formService)
  const [schema, setSchema] = useState({})
  const [uiSchema, setUiSchema] = useState({})
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [data, setData] = useState({})
  const [error, setError] = useState([])
  const { caseId, patientId, formid, isClinical } = useParams()
  const { isLoading } = useAppSelector((state: RootState) => state.formService)

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  useEffect(() => {
    const err = Object.keys(rxJsonForm.data).map((key) => {
      if (
        isSettings &&
        key === "case_specific_instructions" &&
        rxJsonForm.schema.required.includes(key) &&
        !rxJsonForm.data[key]
      ) {
        return { instancePath: "/" + key }
      }
      return undefined
    })
    const filteredErr = err.filter((item) => item !== undefined)
    setError([...filteredErr])
  }, [rxJsonForm.schema])

  useEffect(() => {
    if (!isSaveData) return
    setIsFormSubmitted(true)
    setIsSaveData && setIsSaveData(false)
  }, [isSaveData])

  useEffect(() => {
    if (!isResetData) return
    dispatch(
      deleteFormSavedData({
        formId: formid,
      }),
    ).then((response) => {
      if (response) {
        dispatch(
          fetchFormByCaseIdWithMergeData({
            formId: formid,
            caseId: caseId,
            getSavedData: !isSettings,
            patientId: patientId,
            formType: "SMARTRX",
            uassistRound: 0,
          }),
        )
        setIsResetData && setIsResetData(false)
        dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
      }
    })
  }, [isResetData])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    setSchema({})
    setUiSchema({})
    setIsFormSubmitted(false)
    setError([])
    if (rxJsonForm.id !== formid) {
      dispatch(
        fetchFormByCaseIdWithMergeData({
          formId: formid,
          caseId: caseId,
          getSavedData: !isSettings,
          patientId: patientId,
          uassistRound: 0,
          formType: "SMARTRX",
        }),
      )
    }
  }, [formid])

  useEffect(() => {
    console.log(error, "...error rx form")
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
      return
    } else if (isFormSubmitted) {
      isSettings
        ? dispatch(
            saveDefaultFormData({
              formId: formid,
              payload: rxJsonForm.data,
            }),
          ).then(() => {
            showToaster()
            dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
          })
        : dispatch(
            updateFormSavedData({
              formId: formid,
              caseId: caseId,
              payload: rxJsonForm.data,
              savedId: rxJsonForm.saveid,
              isRefinement: false,
            }),
          ).then(() => {
            showToaster()
            dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
          })
    }
  }, [error, isFormSubmitted])

  useEffect(() => {
    if (
      !checkIfObjectHasKeys(rxJsonForm.schema) ||
      !checkIfObjectHasKeys(rxJsonForm.uischema) ||
      !checkIfObjectHasKeys(rxJsonForm.data)
    )
      return
    if (!isSettings) {
      // eslint-disable-next-line no-prototype-builtins
      if (rxJsonForm.schema.hasOwnProperty("case_specific_instructions")) {
        delete rxJsonForm.schema.case_specific_instructions
      }
    }
    const uiSchema = isSettings
      ? { ...rxJsonForm.uischema }
      : {
          ...rxJsonForm.uischema,
          elements: [...rxJsonForm.uischema.elements].filter(
            (element: any) => element.label !== "Additional Instructions",
          ),
        }
    setSchema({ ...rxJsonForm.schema })
    setUiSchema({ ...uiSchema })
    setData(rxJsonForm.data)
  }, [rxJsonForm.id])

  const handleDataChange = (data: any, errors: any) => {
  if(!isRouteChangeBlocked){
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
  }
    dispatch(updaterxFormData(data))
    if (!errors.length) return
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }

  const showToaster = () => {
    if (isLoading === "succeeded") {
      dispatch(
        setAlert({
          message: t(
            `uassist.uAssistForm.${
              isSettings
                ? "dataSaveStatusSuccess"
                : "uassistDataSaveStatusSuccess"
            }`,
          ),
        }),
      )
      if (!isSettings) {
        if (
          location.pathname.includes(ROUTE_ATTENTION_BANNER.smartRxFormBanner)
        ) {
          navigate(
            `/overview/patient/${patientId}/case/${caseId}/attentionBanner/RxForm`,
          )
        } else {
          (!!isClinical) ? navigate(-1): navigate(`/records/patient/${patientId}/case/${caseId}/rxForm/prescription`)
        }
      }
    } else if (isLoading === "failed") {
      dispatch(
        setAlert({
          message: t(
            `uassist.uAssistForm.${
              isSettings
                ? "dataSaveStatusFailure"
                : "uassitsDataSaveStatusFailure"
            }`,
          ),
          isError: true,
        }),
      )
    }
    setIsFormSubmitted(false)
  }

  return (
    <>
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          padding: 5,
          justifyContent: "space-between",
          width: "640px",
        }}
      >
        {rxJsonForm.name && (
          <>
            <UText
              variant={"h4"}
              component={"div"}
              sxProp={{ textAlign: "center" }}
            >
              <Trans components={{ newLine: <br /> }}>
                {rxJsonForm.name +
                  " " +
                  t(
                    `uassist.uAssistForm.${
                      rxJsonForm.name !== "Limited Treatment"
                        ? "templateHeader"
                        : "limitedtreatmentHeader"
                    }`,
                  )}
              </Trans>
            </UText>
            <UText
              variant={"body2"}
              sxProp={{
                mt: isSettings ? 1 : 0,
                mb: 3,
                textAlign: "center",
                color: "rgb(0,0,0,0.6)",
              }}
            >
              {isSettings ? (
                t(
                  `uassist.uAssistForm.${rxJsonForm.name
                    .toLowerCase()
                    .replaceAll(" ", "")}SettingsDescription`,
                )
              ) : (
                <>
                  {t("uassist.uAssistForm.formInfo")}
                  <br />
                  {rxJsonForm.name === "Limited Treatment"
                    ? t("uassist.uAssistForm.limitedInfo")
                    : ""}
                </>
              )}
            </UText>
          </>
        )}
        {!!(
          checkIfObjectHasKeys(schema) &&
          checkIfObjectHasKeys(uiSchema) &&
          checkIfObjectHasKeys(rxJsonForm.data)
        ) && (
          <JsonFormBuilder
            schema={schema}
            uischema={uiSchema}
            defaultData={rxJsonForm.data}
            handleChange={handleDataChange}
            isFormSubmitted={isFormSubmitted}
            setIsFormSubmitted={setIsFormSubmitted}
            isSettings={isSettings}
          />
        )}
        {!isSettings && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              variant="outlined"
              onClick={() => {
                // dispatch(resetSchema())
                if (
                  location.pathname.includes(
                    ROUTE_ATTENTION_BANNER.smartRxFormBanner,
                  )
                ) {
                  navigate(
                    `/overview/patient/${patientId}/case/${caseId}/attentionBanner/RxForm`,
                  )
                } else {
                  dispatch(setIsRouteChangeBlocked({isRouteChangeBlocked:true}));
                  (!!isClinical) ? navigate(-1): navigate(`/records/patient/${patientId}/case/${caseId}/rxForm/prescription`)
                }
              }}
              sx={{ width: "96px", height: "36px" }}
            >
              <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
              {t("button.back")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(
                  setIsRouteChangeBlocked({ isRouteChangeBlocked: false }),
                )
                setIsFormSubmitted(true)
              }}
              sx={{ width: "180px", height: "36px" }}
            >
              {t("button.saveCase")}
            </Button>
          </Box>
        )}
      </Grid>
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default SmartRxForm
