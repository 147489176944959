import { FC, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  View,
} from "@react-pdf/renderer"
import html2canvas from "html2canvas"
import { PDFDocument } from "pdf-lib"

import { ULoading, UText } from "@/components/index"
import { Box, Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { updateOrder } from "@/core/app/slices/order/orderThunkApi"
import { downloadDentalChart } from "@/core/app/slices/records/xrays/xraysThunkApi"
import {
  fetchFormByCaseIdWithMergeData,
  saveUassistSummary,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys, parseToObject } from "@/core/utils/formatters"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"
import {
  generateRfSummaryData,
  generateRfSummarySchema,
  generateRfSummaryUiSchema,
  generateSummaryData,
  generateSummarySchema,
  generateSummaryUiSchema,
} from "@/rx-form/Json/Summary"

import { NoUassistPopup } from "./NoUassistPopup"
import { resetPrescriptionSchema } from "@/core/app/slices/uassist/formSlice"
import { caseManagement } from "@/gluelayer"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"

const A4Height = 792
const A4Margin = 50

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 1,
    paddingTop: `${A4Margin}px`,
    paddingBottom: `${A4Margin}px`,
  },
})

const addPagesToMergedPDF = async (mergedPdfDoc, pdfDoc, pageIndices) => {
  for (const index of pageIndices) {
    const [pdfPage] = await mergedPdfDoc.copyPages(pdfDoc, [index])
    mergedPdfDoc.addPage(pdfPage)
  }
}

const exportComponentAsPDF = (
  componentRef: React.RefObject<HTMLDivElement>,
  dispatch: any,
  archesToTreat: any,
  t: any,
  navigate: any,
  payload: {
    patientId: string
    caseId: string
  },
  isRefinement: boolean,
  isRetainer: boolean,
  rxJsonForm: any,
  setActionType: (val: string) => void,
  dentalChart:Blob,
  clinicalSettings?: object,
  unlockFunc?: () => void,
  needToSubmit?:boolean
) => {
  if (!componentRef.current) {
    console.warn("the component which is exported to PDF is null !")
    return
  }
  componentRef.current.style.display = 'block';

  // Now you have the Blob object, and you can use it as needed.
  const width = componentRef.current?.clientWidth

  // calculate page height range without cutting-out elements in last
  let parent = componentRef.current
  let pageDimensionArr = []
  let currentPage = 0
  const pageH = A4Height - 2 * A4Margin
  let lastScannedH = 0
  let parentDimension = parent.getBoundingClientRect()

  parent.querySelectorAll(".summary-data-row").forEach((el, index) => {
    if (!pageDimensionArr[currentPage]) {
      pageDimensionArr[currentPage] = {
        start: lastScannedH,
        end: lastScannedH + pageH,
        lastChildEl: null,
      }
    }
    let elDimension = el.getBoundingClientRect()
    let elRelTop = Math.abs(parentDimension.top - elDimension.top)
    let elRelBottom = elRelTop + elDimension.height

    if (pageDimensionArr[currentPage].end > elRelBottom) {
      // element is within render range
      lastScannedH = elRelBottom
      pageDimensionArr[currentPage].lastChildEl = el
    } else {
      // element is NOT within render range
      lastScannedH = elRelTop
      pageDimensionArr[currentPage].end = elRelTop
      pageDimensionArr[currentPage + 1] = {
        start: elRelTop,
        end: elRelTop + pageH,
        lastChildEl: el,
      }
      currentPage += 1
    }
  })

  const imgPromises = []

  for (let i = 0; i < pageDimensionArr.length; i++) {
    const imgPromise = html2canvas(componentRef.current, {
      y: pageDimensionArr[i].start,
      height: pageDimensionArr[i].end - pageDimensionArr[i].start,
    }).then((canvas) => {
      return canvas.toDataURL("image/png")
    })

    imgPromises.push(imgPromise)
  }

  Promise.all(imgPromises).then((imgDataArray) => {
    const MakePDFComponent = () => (
      <Document>
        {imgDataArray.map((imgData, i) => {
          return (
            <Page key={i} style={styles.page} size="letter">
              <View>
                <Image
                  style={{ width, height: `${pageH}px` }}
                  source={imgData}
                  cache={true}
                />
              </View>
            </Page>
          )
        })}
      </Document>
    )

    pdf(<MakePDFComponent />)
      .toBlob()
      .then(async (blob) => {
        if (isRefinement) {
          // const link = document.createElement("a")
          // link.href = URL.createObjectURL(blob)
          // link.download = "refinement.pdf"
          // link.click()
          const formData = new FormData()
          formData.append(
            "attachment",
            new File([blob], `refinement.pdf`, {
              type: "application/pdf",
            }),
          )
          formData.append("is_refinement", "1")
          dispatch(
            saveUassistSummary({
              patientId: payload.patientId,
              caseId: payload.caseId,
              payload: formData,
              isRefinement,
            }),
          ).then((res: any) => {
            const errorMessage = t("uassist.uAssistForm.uassistSubmitFailed")
            if (res.payload.status === "Success") {
              navigate(
                `/overview/patient/${payload.patientId}/case/${payload.caseId}/refinement/confirmation`,
              )
            } else {
              dispatch(
                setAlert({
                  message: errorMessage,
                  isError: true,
                }),
              )
            }
          })
        } else {
          const pdfDoc1 = await PDFDocument.load(await blob.arrayBuffer())
          const pdfDoc2 = dentalChart
            ? await PDFDocument.load(await dentalChart.arrayBuffer())
            : null

          const mergedPdfDoc = await PDFDocument.create()

          const pageIndices1 = Array.from(
            { length: pdfDoc1.getPageCount() },
            (_, i) => i,
          )
          // Copy and add pages from pdfDoc1 and pdfDoc2
          await addPagesToMergedPDF(mergedPdfDoc, pdfDoc1, pageIndices1)

          if (pdfDoc2) {
            const pageIndices2 = Array.from(
              {length: pdfDoc2.getPageCount()},
              (_, i) => i,
            )
            await addPagesToMergedPDF(mergedPdfDoc, pdfDoc2, pageIndices2)
          }

          // Save the merged PDF
          const mergedPdfBlob = new Blob([await mergedPdfDoc.save()], {
            type: "application/pdf",
          })

          // Use this block to download summary to file manager
          // const link = document.createElement("a")
          // link.href = URL.createObjectURL(mergedPdfBlob)
          // link.download = "summary.pdf"
          // link.click()

          // Create form data and upload to api
          let pdfName = ""
          isRetainer
            ? (pdfName = "retainer-summary.pdf")
            : (pdfName = "summary.pdf")
          const formData = new FormData()
          formData.append(
            "attachment",
            new File([mergedPdfBlob], pdfName, {
              type: "application/pdf",
            }),
          )
          dispatch(
            saveUassistSummary({
              patientId: payload.patientId,
              caseId: payload.caseId,
              payload: formData,
              isRefinement,
              isRetainer,
            }),
          ).then((res: any) => {
            if(!needToSubmit){
              // setActionType("success")
              return
            }
            const errorMessage = t("uassist.uAssistForm.uassistSubmitFailed")
            if (res.payload.status === "Success") {
              // if (
              //   location.pathname.includes(
              //     `${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
              //   ) ||
              //   location.pathname.includes(
              //     `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
              //   )
              // ) {
              //   dispatch(
              //     postMessageData({
              //       patientId: payload.patientId,
              //       caseId: payload.caseId.toString(),
              //       payload: {
              //         message: "Rx Form updated",
              //       },
              //     }),
              //   ).then((res) => {
              //     if (res && res.payload && res.payload.status === "Success") {
              //       dispatch(getCaseByPatient({ patientId: payload.patientId }))
              //       navigate(`/overview/patient/${payload.patientId}/`)
              //     }
              //   })
              // } else
              let upper = ""
              let lower = ""
              let bracket_removal = ""

              if (isRetainer) {
                lower = rxJsonForm.data.arches_to_treat.includes("lower")
                  ? "R"
                  : ""
                upper = rxJsonForm.data.arches_to_treat.includes("upper")
                  ? "R"
                  : ""
                bracket_removal =
                  rxJsonForm.data.bracket_removal?.value?.includes("yes")
              } else {
                upper = archesToTreat.upper
                  ? archesToTreat.upper.aligner_type === "Aligner"
                    ? "A"
                    : "R"
                  : ""

                lower = archesToTreat.lower
                  ? archesToTreat.lower.aligner_type === "Aligner"
                    ? "A"
                    : "R"
                  : ""
              }
                dispatch(
                  updateOrder({
                    caseId: payload.caseId,
                    type: isRetainer
                      ? bracket_removal
                        ? "Bracket Removal Uassist"
                        : "Retainer Uassist"
                      : "uAssist",
                    upper: upper,
                    lower: lower,
                  }),
                ).then((result: any) => {
                  if (result.payload.status === "Success") {
                    // upload zips
                    caseManagement
                      .saveJsonDataToZip(
                        JSON.stringify(clinicalSettings),
                        "uassist_team_settings.json",
                        "Setting Data2",
                      )
                      .then((res) => {
                        if (res) {
                          caseManagement
                            .getCaseFiles(["bulk2", "bulk10"])
                            .then((r) => {
                              const zips = []
                              if (r) {
                                for (const k in r) {
                                  zips.push({
                                    fileName: k,
                                    file: r[k],
                                  })
                                }
                                dispatch(
                                  uploadCaseZips({
                                    zips,
                                    patientId: payload.patientId,
                                    caseId: payload.caseId,
                                  }),
                                ).then((res) => {
                                  setActionType("success")
                                  if (unlockFunc) {
                                    unlockFunc()
                                  }
                                  dispatch(resetPrescriptionSchema())
                                })
                              }
                            })
                        }
                      })
                  } else {
                    dispatch(
                      setAlert({
                        message: errorMessage,
                        isError: true,
                      }),
                    )
                  }
                })
            } else {
              dispatch(
                setAlert({
                  message: errorMessage,
                  isError: true,
                }),
              )
            }
          })
        }
      })
  })
}

const SmartSummary: FC<{
  setSubmitPdf: (val: boolean) => void
  setActionType: (val: string) => void
  actionType: string
  formUIschemaId: string
  isPreview?: boolean
  submitPdf?: boolean
  hideSummary?: () => void
  isRefinement?: boolean
  isRetainer?: boolean
  unlockFunc?: () => void
  needToSubmit?: boolean
  reviewNum?:number
}> = ({
  isPreview,
  hideSummary,
  isRefinement,
  isRetainer,
  submitPdf,
  setSubmitPdf,
  setActionType,
  actionType,
  formUIschemaId,
  unlockFunc,
  needToSubmit,
  reviewNum
}) => {
  const { clinicalSettings } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const [uiSchema, setUiSchema] = useState({})
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)
  const { caseId, patientId } = useParams()
  const { formTemplateList,  rxJsonForm, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { dentalChart } = useAppSelector(
    (state: RootState) => state.xrayService,
  )

  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const contentToPDF = useRef(null)

useEffect(() => {
  if (!needToSubmit && contentToPDF?.current) 
    {
      dispatch(downloadDentalChart({ patientId, caseId })).then(()=>{
        setSubmitPdf(true)
      })
    }
}, [needToSubmit,contentToPDF])

useEffect(() => {
    window.scrollTo({ top: 0 })
    setUiSchema({})
    if (isRetainer && rxJsonForm?.id === getFormByName(formTemplateList, 'UASSIST', FORM_CONSTANT.RETAINER).id) {
      // dispatch(downloadDentalChart({ patientId, caseId }))
      return
    }
    // dispatch(
    //   fetchFormByCaseIdWithMergeData({
    //     formId: formUIschemaId,
    //     caseId: caseId,
    //     getSavedData: true,
    //     courseCheckNumber: 0,
    //     patientId: patientId,
    //     formType: "UASSIST",
    //   }),
    // ).then((res: any) => {
    //   if (isRefinement || isRetainer) return
    //   const defaultData = parseToObject(res.payload.default_data)
    //   if (defaultData && defaultData.smart_rx_template_id) {
    //     const tempFormId = parseToObject(res.payload.default_data)
    //       ?.smart_rx_template_id?.id
    //     if (tempFormId) {
    //       dispatch(
    //         fetchFormByCaseIdWithMergeData({
    //           formId: tempFormId,
    //           caseId: caseId,
    //           getSavedData: true,
    //           patientId: patientId,
    //           formType: "SMARTRX",
    //         }),
    //       )
    //     }
    //   }
    // })
    if (!isRefinement) dispatch(downloadDentalChart({ patientId, caseId }))
  }, [])

  const patientDetail = [
    {
      label: "Patient Name",
      value: patientData?.firstName + " " + patientData?.lastName,
    },
    {
      label: "Patient Id",
      value: patientId,
    },
    {
      label: "Case Id",
      value: caseId,
    },
  ]

  useEffect(() => {
    if (checkIfObjectHasKeys(rxJsonForm.uischema)) {
      const uiSchema = {
        ...rxJsonForm.uischema,
        elements: [...rxJsonForm.uischema.elements].filter(
          (element: any) => element.label !== "Additional Instructions",
        ),
      }
      setUiSchema({ ...uiSchema })
    }
  }, [rxJsonForm])

  // useEffect(() => {
  //   if (
  //     (isRefinement || isRetainer) &&
  //     checkIfObjectHasKeys(rxJsonForm.data) &&
  //     checkIfObjectHasKeys(uiSchema)
  //   ) {
  //     setTimeout(() => {
  //       exportComponentAsPDF(
  //         contentToPDF,
  //         dispatch,
  //         rxPrescription.data.arches_to_treat,
  //         t,
  //         navigate,
  //         {
  //           patientId: patientId,
  //           caseId: caseId,
  //         },
  //         isRefinement,
  //         isRetainer,
  //         rxJsonForm,
  //         setActionType,
  //         clinicalSettings,
  //         unlockFunc,
  //       )
  //     }, 1500)
  //   }
  // }, [uiSchema])

  useEffect(() => {
    if (submitPdf) {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        exportComponentAsPDF(
          contentToPDF,
          dispatch,
          rxPrescription.data.arches_to_treat,
          t,
          navigate,
          {
            patientId: patientId,
            caseId: caseId,
          },
          isRefinement,
          isRetainer,
          rxJsonForm,
          setActionType,
          dentalChart,
          clinicalSettings,
          unlockFunc,
          needToSubmit,
        )
      }
    }
  }, [submitPdf])

  return (
    <>
      {/* <ULoading isLoading={submitPdf && actionType !== "success"} /> */}
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setSubmitPdf}
      />
      <Grid
        id="123"
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          minHeight: "615px",
          padding: 0,
          justifyContent: !isPreview && "space-between",
        }}
      >
        {isPreview && (
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "end",
              height: "64px",
              position: "sticky",
              top: 0,
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "white",
              marginRight: "-24px",
            }}
          >
            <CloseIcon
              fontSize={"medium"}
              sx={{
                color: "rgba(0, 0, 0, 0.56)",
                fontSize: "24px",
                cursor: "pointer",
              }}
              onClick={hideSummary}
            />
          </Box>
        )}

        {reviewNum > 0 &&(<Box component={"div"}>
          <UText
            variant={"h4"}
            component={"div"}
            sxProp={{ textAlign: "center" }}
          >
            <Trans components={{ newLine: <br /> }}>
              {rxPrescription?.name.includes("Refinement")
                ? "uassist.refinement.title"
                : "uassist.summary.title"}
            </Trans>
          </UText>
          {!isPreview && (
            <Box sx={{ height: "24px" }}>
              <UText
                component={"div"}
                variant={"body2"}
                sxProp={{ textAlign: "center", color: "rgb(0,0,0,0.6)" }}
              >
                {t("uassist.summary.summarydesc")}
              </UText>
            </Box>
          )}
        </Box>)}
        {!!(
          checkIfObjectHasKeys(rxJsonForm.schema) &&
          checkIfObjectHasKeys(uiSchema) &&
          checkIfObjectHasKeys(rxJsonForm.data) &&
          (isRefinement ||
            isRetainer ||
            (checkIfObjectHasKeys(rxPrescription.schema) &&
              checkIfObjectHasKeys(rxPrescription.uischema) &&
              checkIfObjectHasKeys(rxPrescription.data)))
        ) ? (
          <Box sx={{ pt: "4px", mt: 1 }}>
            <JsonFormBuilder
              schema={
                isRefinement || isRetainer
                  ? generateRfSummarySchema(rxJsonForm.schema)
                  : generateSummarySchema(
                      rxPrescription.schema,
                      rxJsonForm.schema,
                    )
              }
              uischema={
                isRefinement || isRetainer
                  ? generateRfSummaryUiSchema(rxJsonForm.uischema)
                  : generateSummaryUiSchema(
                      rxPrescription.uischema,
                      rxPrescription.data,
                      uiSchema,
                      rxJsonForm.data,
                      false,
                    )
              }
              defaultData={
                isRefinement || isRetainer
                  ? generateRfSummaryData({ ...rxJsonForm.data })
                  : generateSummaryData({
                      ...rxPrescription.data,
                      ...rxJsonForm.data,
                    })
              }
            />
          </Box>
        ) : (
          <Box>No uAssist form submitted / self-planned</Box>
        )}
      </Grid>
      {!isPreview && (
        <Grid id="567">
          <Box
            component={"div"}
            ref={contentToPDF}
            sx={{ zIndex: -1, position: "fixed", top: "120vh", display: "none" }}
          >
            <Box component={"div"}>
              <UText
                variant={"h4"}
                component={"div"}
                sxProp={{ textAlign: "center" }}
              >
                <Trans components={{ newLine: <br /> }}>
                  {"uassist.summary.title"}
                </Trans>
              </UText>
            </Box>
            {patientDetail.map((data) => {
              return (
                <Box
                  sx={{
                    py: 1,
                    mb: "4px",
                    display: "flex",
                    flexDirection: "row",
                    pl: 1,
                  }}
                  key={data.label}
                >
                  <UText
                    variant={"body2"}
                    sxProp={{
                      width: 200,
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    {data.label}
                  </UText>
                  <Box>
                    <UText
                      component={"div"}
                      variant={"subtitle2"}
                      sxProp={{
                        ml: 1,
                        lineHeight: "16px",
                        color: "text.primary",
                      }}
                    >
                      {data.value}
                    </UText>
                  </Box>
                </Box>
              )
            })}
            {!!(
              checkIfObjectHasKeys(rxJsonForm.schema) &&
              checkIfObjectHasKeys(uiSchema) &&
              checkIfObjectHasKeys(rxJsonForm.data) &&
              (isRefinement ||
                isRetainer ||
                (checkIfObjectHasKeys(rxPrescription.schema) &&
                  checkIfObjectHasKeys(rxPrescription.uischema) &&
                  checkIfObjectHasKeys(rxPrescription.data)))
            ) && (
              <Box sx={{ pt: "4px", mt: 1 }}>
                <JsonFormBuilder
                  schema={
                    isRefinement || isRetainer
                      ? generateRfSummarySchema(rxJsonForm.schema)
                      : generateSummarySchema(
                          rxPrescription.schema,
                          rxJsonForm.schema,
                        )
                  }
                  uischema={
                    isRefinement || isRetainer
                      ? generateRfSummaryUiSchema(rxJsonForm.uischema)
                      : generateSummaryUiSchema(
                          rxPrescription.uischema,
                          rxPrescription.data,
                          uiSchema,
                          rxJsonForm.data,
                          true,
                        )
                  }
                  defaultData={
                    isRefinement || isRetainer
                      ? generateRfSummaryData({ ...rxJsonForm.data })
                      : generateSummaryData({
                          ...rxPrescription.data,
                          ...rxJsonForm.data,
                        })
                  }
                />
              </Box>
            )}
          </Box>
        </Grid>
      )}
    </>
  )
}

export default SmartSummary
