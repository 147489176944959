import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { moduleMeshEditV2, wasmModule } from "@/gluelayer"
import { Stack, Button, Typography, Checkbox } from "@mui/material"
import { Grid, Slider } from "@mui/material"

export const ToothBasisEdit = (props) => {
  const [meshInfos, setMeshInfos] = useState([])
  const [xfmValue, setXfmValue] = useState([])
  const names = ["x", "y", "z", "Rx", "Ry", "Rz"]
  const getMeshInfos = () => {
    const infoStr = moduleMeshEditV2.GetAllMeshInfosStr()
    const meshInfos = JSON.parse(infoStr)
    setMeshInfos(meshInfos)
  }
  useEffect(() => {
    if (!wasmModule.isInit) return
    console.log("Open mesh edit module")
    moduleMeshEditV2.SwitchModuleMeshEditV2(true)
    moduleMeshEditV2.SetEditMode(2)
    getMeshInfos()
    return () => {
      console.log("End mesh edit module")
      moduleMeshEditV2.SwitchModuleMeshEditV2(false)
    }
  }, [])
  const onChangeSlide = (id, value) => {
    // moduleMeshEditV2.SetEditMode(1)
    let newXfmValue = xfmValue
    moduleMeshEditV2.AdjustCurToothBasis(id, value)
    newXfmValue[id] = value
    setXfmValue(newXfmValue)
  }
  const onChangeSlideCommitted = (id, value) => {
    // moduleMeshEditV2.SetEditMode(1)
    let newXfmValue = xfmValue
    moduleMeshEditV2.AdjustCurToothBasis(id, value)
    newXfmValue[id] = value
    setXfmValue(newXfmValue)
  }

  const OneSlider = (props) => {
    return (
      <>
        <Grid item xs={4}>
          <Typography variant="body1">{names[props.id]}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Slider
            value={xfmValue[props.id]}
            defaultValue={0}
            valueLabelDisplay="auto"
            step={0.1}
            min={props.range[0]}
            max={props.range[1]}
            onChange={(e, value) => {
              onChangeSlide(props.id, value)
            }}
            onChangeCommitted={(e, value) => {
              onChangeSlideCommitted(props.id, value)
            }}
          />
        </Grid>
      </>
    )
  }

  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <Button variant="contained" onClick={() => {}}>
          Show All
        </Button>
        <Button variant="contained" onClick={() => {}}>
          Hide All
        </Button>
      </Stack>
      <Grid container spacing={0.1}>
        <OneSlider id={0} range={[-10, 10]} />
        <OneSlider id={1} range={[-10, 10]} />
        <OneSlider id={2} range={[-10, 10]} />
        <OneSlider id={3} range={[-45, 45]} />
        <OneSlider id={4} range={[-45, 45]} />
        <OneSlider id={5} range={[-45, 45]} />
      </Grid>
    </Stack>
  )
}
