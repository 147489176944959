import { wasmModule } from "../wasm/wasmModule"

class ModuleMeshEditV2 {
  SwitchModuleMeshEditV2(onOff: boolean) {
    if (!wasmModule.isInit) return
    wasmModule.ulabwinIns.getModuleManager().SwitchModuleMeshEditV2(onOff)
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    console.log("mmmm: ", mm)
  }
  SetEditMode(mode) {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    mm.SetEditMode(mode)
  }
  SetPickRadius(radius) {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    mm.SetPickRadius(radius)
  }
  UndoRedo(doUndo) {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    mm.UndoRedo(doUndo)
  }
  GetAllMeshInfosStr() {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    return mm.GetAllMeshInfosStr()
  }
  ShowMesh(tid, show) {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    mm.ShowMesh(tid, show)
  }
  ShowCurToothMeshOnly(showOnly) {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    mm.ShowCurToothMeshOnly(showOnly)
  }
  AdjustCurToothBasis(idx, value) {
    if (!wasmModule.isInit) return
    const mm = wasmModule.ulabwinIns.getModuleManager().GetModuleMeshEditV2()
    mm.AdjustCurToothBasis(idx as number, value as number)
  }
}
export const moduleMeshEditV2 = new ModuleMeshEditV2()
